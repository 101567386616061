import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { AuthService } from "./authService";
import { catchError, switchMap } from "rxjs/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  user: any;
  constructor(private authService: AuthService, private router: Router) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let accessToken = this.authService.getAccessToken();

    if (accessToken) {
      if (this.authService.isTokenExpired(accessToken)) {
        req = this.addToken(req, accessToken);
      } else {
        this.authService.refreshToken();
      }
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return this.handle401Error(req, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handle401Error(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authService.refreshToken().pipe(
      switchMap((tokens: any) => {
        if (tokens) {
          return next.handle(this.addToken(req, tokens.accessToken));
        } else {
          this.authService.logout();
          return throwError("Refresh token expired");
        }
      }),
      catchError(() => {
        this.authService.logout();
        return throwError("Session expired");
      })
    );
  }
}
