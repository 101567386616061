import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Urls } from "../urls";

@Injectable()
export class AuthService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private urls: Urls
  ) {}
  getAccessToken(): string | null {
    return sessionStorage.getItem("access_token");
  }

  getRefreshToken(): string | null {
    return sessionStorage.getItem("refresh_token");
  }
  setTokens(accessToken: string, refreshToken: string) {
    sessionStorage.setItem("access_token", accessToken);
    sessionStorage.setItem("refresh_token", refreshToken);
  }
  isTokenExpired(token: string): boolean {
    if (!token) return true;

    const expiry = JSON.parse(atob(token.split(".")[1])).exp;
    return Math.floor(new Date().getTime() / 1000) <= expiry;
  }

  logout() {
    sessionStorage.clear();
    this.router.navigate(["/"]);
  }

  refreshToken(): Observable<any> {
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) {
      this.logout();
      return of(null);
    }
    return this.http
      .post<any>(this.urls.refreshTokenUrl, { refreshToken: refreshToken })
      .pipe(
        map((tokens: any) => {
          if (
            tokens.status === "REFRESH_TOKEN_WAS_EXPIRED" ||
            tokens.status === "INVALID_REFRESH_TOKEN"
          ) {
            sessionStorage.clear();
            this.logout();
          }
          this.setTokens(tokens.accessToken, tokens.refreshToken);
          return tokens;
        }),
        catchError(() => {
          this.logout();
          return of(null);
        })
      );
  }
}
