import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TestCodeService } from "../services/test-code.service";
import { BaseService } from "../services/baseService";
import { CandidateInfoService } from "../services/candidate-info.service";
import { InstructionsService } from "../services/instructions.service";
import { Store } from "@ngrx/store";
import { UserActionTypes } from "../onlineTestReducer";
import { DatePipe, LocationStrategy } from "@angular/common";
@Component({
  selector: "app-candidate-form",
  templateUrl: "./candidate-form.component.html",
  styleUrls: [
    "./candidate-form.component.css",
    "../commonCss.css",
    "../../assets/font-awesome/css/font-awesome.min.css",
  ],
})
export class CandidateFormComponent implements OnInit {
  candidId: any = [];
  genderTypes: any = [];
  govtIdTypes: any = [];
  existCand: boolean = true;
  fname: string;
  gender: string;
  emailId: string;
  mobNo: string;
  degree: string;
  yearOfGraduation: number;
  percentage: number;
  department: string;
  college: string;
  university: string;
  govIdType: string;
  govIdNo: string;
  recentEmpName: string;
  empPosition: string;
  startDate: string;
  workingStatus: boolean = false;
  endDate: string;
  candidateInfo: any = [];
  isLoading: boolean = false;
  currentCity: string;
  totExpYear: number;
  totExpMon: number;
  recEmpNull: boolean;
  pgender: string = "";
  pgovIdType: string = "";
  pworkingStatus: boolean = false;
  resumeCandidate: any = [];
  isLateralTest: boolean;
  isDisabled: boolean = false;
  invalidPercentage: boolean = false;
  isInvalidDate: boolean = false;
  dateStartDate: Date;
  dateEndDate: Date;
  pdegree: string = "";
  selectedDegree: string | undefined;
  constructor(
    private router: Router,
    private testDetails: TestCodeService,
    private baseService: BaseService,
    private saveCandidate: CandidateInfoService,
    private instuctions: InstructionsService,
    private store: Store<any>,
    private datePipe: DatePipe,
    private location: LocationStrategy,
    private testInfo: TestCodeService
  ) {
    this.location.onPopState(() => {
      this.baseService.isBackButtonClicked = true;
      return false;
    });
  }
  ngOnInit() {
    this.resumeCandidate =
      this.testDetails.allTestDetails.testCodeInfo.candidate;
    this.isLateralTest = this.testDetails.isLateralTest;
    this.store.dispatch({ type: UserActionTypes.GET_GENDER });
    this.store.select("gender").subscribe((data) => {
      this.genderTypes = data.gender;
    });

    this.emailId = this.testDetails.emailId;

    this.store.dispatch({ type: UserActionTypes.GET_GOVTID });
    this.store.select("govtId").subscribe((data) => {
      this.govtIdTypes = data.govtId;
    });

    if (this.testDetails.allTestDetails.testCodeInfo.candidate != null) {
      sessionStorage.setItem("name", this.resumeCandidate.name);
      this.isDisabled = true;
      this.fname = this.resumeCandidate.name;
      this.pgender = this.resumeCandidate.gender;
      this.emailId = this.resumeCandidate.emailId;
      this.mobNo = this.resumeCandidate.phoneNo;
      this.pdegree = this.resumeCandidate.degree;
      this.yearOfGraduation = this.resumeCandidate.graduationYear;
      this.percentage = this.resumeCandidate.percentage;
      this.department = this.resumeCandidate.department;
      this.college = this.resumeCandidate.collegeName;
      this.pgovIdType = this.resumeCandidate.govtUIDType;
      this.govIdNo = this.resumeCandidate.govtUID;
      this.recentEmpName = this.resumeCandidate.currentEmployer;
      this.empPosition = this.resumeCandidate.currentPosition;
      this.startDate = this.datePipe.transform(
        this.resumeCandidate.currentEmpStartDate,
        "dd-MM-yyyy"
      );
      this.workingStatus = this.resumeCandidate.currentlyWorking;
      this.endDate = this.datePipe.transform(
        this.resumeCandidate.currentEmpEndDate,
        "dd-MM-yyyy"
      );
      this.currentCity = this.resumeCandidate.currentEmpCity;
      this.totExpYear = this.resumeCandidate.totalExpYears;
      this.totExpMon = this.resumeCandidate.totalExpMonths;
    }
  }
  percentageValidation() {
    this.invalidPercentage = false;
    if (this.percentage < 0 || this.percentage > 100) {
      this.invalidPercentage = true;
    }
  }
  selGender(event: any) {
    this.gender = event.value;
  }
  selGovId(event: any) {
    this.govIdType = event.value;
  }
  selDegree(event: any) {
    this.degree = event.value;
  }
  workTrue() {
    this.workingStatus = !this.workingStatus;
  }
  dateValidation() {
    if (parseInt(this.startDate) > parseInt(this.endDate)) {
      this.isInvalidDate = true;
    }
  }
  dateFocus() {
    if (this.isInvalidDate) {
      this.isInvalidDate = false;
    }
  }
  saveCandDet() {
    this.router.navigate(["instructions"]);
    if (this.testDetails.allTestDetails.testCodeInfo.candidate == null) {
      this.saveCandidate.existCandidate = this.existCand;
      this.saveCandidate.candidateDetails = {
        name: this.fname,
        emailId: this.emailId,
        degree: this.degree,
        collegeName: this.college,
        department: this.department,
        graduationYear: this.yearOfGraduation,
        percentage: Math.round((this.percentage * 100.0) / 100.0),
        phoneNo: this.mobNo,
        govtUIDType: this.govIdType,
        govtUID: this.govIdNo,
        currentEmployer: this.recentEmpName,
        currentPosition: this.empPosition,
        currentEmpStartDate: this.startDate,
        currentlyWorking: this.workingStatus,
        currentEmpEndDate: this.endDate,
        gender: this.gender,
        currentEmpCity: this.currentCity,
        totalExpYears: this.totExpYear,
        totalExpMonths: this.totExpMon,
        testCode: sessionStorage.getItem("testcode"),
      };

      this.store.dispatch({ type: UserActionTypes.SAVE_CANDIDATE });
      this.store.select("saveCandidate").subscribe((data) => {
        this.isLoading = data.loading;
        if (data.saveCandidate) {
          this.testInfo.allTestDetails.testCodeInfo.candidate =
            data.saveCandidate.candidate;
          this.candidId = data.saveCandidate.candidate.canId;
          this.instuctions.canId = this.candidId;
          sessionStorage.setItem("access_token", data.saveCandidate.token);
          this.baseService.access_token = data.saveCandidate.token;

          sessionStorage.setItem(
            "refresh_token",
            data.saveCandidate.refreshToken
          );

          sessionStorage.setItem("name", data.saveCandidate.candidate.name);
          sessionStorage.setItem(
            "emailId",
            data.saveCandidate.candidate.emailId
          );
          sessionStorage.setItem(
            "userDetails",
            JSON.stringify(data.saveCandidate.candidate)
          );

          this.router.navigate(["instructions"]);
        }
      });
    } else {
      if (
        this.testDetails.allTestDetails.testCodeInfo.candidate.isAllowedForTest
      ) {
        this.instuctions.canId =
          this.testDetails.allTestDetails.testCodeInfo.candidate.canId;
        this.router.navigate(["instructions"]);
      } else {
        this.router.navigate(["notAllowedForTest"]);
      }
    }
  }
}

// pfname: string = "John Doe";
// pgender: string = "Select Gender";
// pemailId: string = "Eg: abcd@gmail.com";
// pmobNo: string = "9876543210";
// pdegree: string = "Eg: B.Tech";
// pyearOfGraduation: number = 2010;
// ppercentage: number = 90;
// pdepartment: string = "Eg: CSE";
// pcollege: string = "Eg: Cvr";
// puniversity: string = "Eg: Jntu";
// pgovIdType: string = "Select Gov Id";
// pgovIdNo: string = "Eg: 1234567890";
// precEmpName: string = "Eg: Microsoft";
// pempPosition: string = "Eg: Associate Engineer";
// pstDate: Date;
// pworkingStatus: boolean;
// peDate: Date;
// pcurrentCity: string = "Eg: Hyderabad";
// ptotExpYear: number = 2;
// ptotExpMon: number = 10;

// validateEmailId() {
//   this.validEmail.uemail = this.emailId;
//   this.validEmail.getCandidateData().subscribe(data => {
//     this.candidateInfo = data;
//     this.validEmail.cand = data;
//     this.candidId = this.candidateInfo.canId;
//     this.existCand = false;
//   })
//   .log(this.candidateInfo + "bdjkdgjk")
// }
// .log(this.testDeatils.allTestDetails)
// this.validTsCode.getTestDet().subscribe(data => {
//   this.testCodeObj = data;
//   this.isLateralTest = this.testCodeObj.lateralTest;
// });
