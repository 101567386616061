import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class Urls {
  // validateDetails:string='';
  // constructor(private testDetails: TestCodeService) { }
  // public validateTestUrl: string = this.sourceUrl+"/validateTest/";
  //public sourceUrl: string = "http://52.66.232.155:8998/assessmentapp"
  public sourceUrl: string = "${{APIBASEURL}}$";
  //  public sourceUrl: string = "http://192.168.0.29:8998/assessmentapp"
  // public sourceUrl: string = "http://localhost:8998/compass";
  public emailIdUrl: string =
    this.sourceUrl + "/getCandidateByEmailorId?emailId=";
  public sectionsUrl: string = this.sourceUrl + "/api/auth/getSections/";
  public genderUrl: string = this.sourceUrl + "/api/auth/getTypes/GENDER";
  public govIdTypesUrl: string = this.sourceUrl + "/api/auth/getTypes/GOVTUID";
  public saveCandUrl: string = this.sourceUrl + "/api/auth/saveCandidate/";
  public startTestUrl: string = this.sourceUrl + "/candidate/startTest";
  public resumeTestUrl: string = this.sourceUrl + "/candidate/resumeTest";
  public certificateUrl: string =
    this.sourceUrl + "/api/auth/certificateVerification";
  public getApplicationByEmpId: string =
    environment.BaseUrl + "/getapplications/";
  public refreshTokenUrl: string = this.sourceUrl + "/api/auth/refreshtoken";
  public saveEmployeedUrl: string = this.sourceUrl + "/api/auth/saveEmployee/";
  public getCandidateRemainingTestTimeUrl: string =
    this.sourceUrl + "/api/auth/getcandidateremainingtesttime/";

  getElaspsedTimeUrl(
    testId: number,
    candidateId: number,
    testCode: string
  ): string {
    return (
      this.sourceUrl +
      "/candidate/getElapsedTime?testId=" +
      testId +
      "&canId=" +
      candidateId +
      "&testCode=" +
      testCode
    );
  }
  validateDetails(
    testCode: string,
    emailId: string,
    isExternalDrive: boolean
  ): string {
    return (
      this.sourceUrl +
      "/api/auth/validateDetails?testCode=" +
      testCode +
      "&emailId=" +
      emailId +
      "&isExternalDrive=" +
      isExternalDrive
    );
  }
  validateTestCodeUrl(testCode: string): string {
    return this.sourceUrl + "/api/auth/validateTestCode?testCode=" + testCode;
  }
  public saveAnswer: string = this.sourceUrl + "/candidate/saveAnswer";
  public clearAnswer: string = this.sourceUrl + "/candidate/clearAnswer";
  public endTest: string = this.sourceUrl + "/candidate/endTest";
  public updateSummary: string =
    this.sourceUrl + "/api/auth/candidate/updateSummary";

  public getEmployeeRolesByEmail: string =
    environment.BaseUrl + "/getemployeeroles/";
}
