import { AuthConfig } from "angular-oauth2-oidc";

export const authConfig: AuthConfig = {
  issuer:
    "https://login.microsoftonline.com/3033642d-6adf-4ac6-bbc5-511b42bc5f00/v2.0",
  redirectUri: window.location.origin,
  clientId: "befe8b8f-956a-47f3-ba55-7c61e36e93eb",
  responseType: "code",
  strictDiscoveryDocumentValidation: false,
  scope: "openid profile email api://befe8b8f-956a-47f3-ba55-7c61e36e93eb/app",
  requestAccessToken: true,
  silentRefreshTimeout: 30000,
};
