import { CandidateInfoService } from "./../services/candidate-info.service";
import { Component, OnInit } from "@angular/core";
import { QuestionsService } from "../services/questions.service";
import { InstructionsService } from "../services/instructions.service";
import { TestCodeService } from "../services/test-code.service";
import { BaseService } from "../services/baseService";

import { Store } from "@ngrx/store";
import { UserActionTypes } from "../onlineTestReducer";
import { Router } from "@angular/router";
import { LocationStrategy } from "@angular/common";
import { TsCodeValid } from "../tsCodeValid";
import {
  debounceTime,
  distinctUntilChanged,
  last,
  switchMap,
  takeLast,
  tap,
} from "rxjs/operators";
import { of } from "rxjs";
@Component({
  selector: "app-instructions",
  templateUrl: "./instructions.component.html",
  styleUrls: [
    "./instructions.component.css",
    "../commonCss.css",
    "../../assets/font-awesome/css/font-awesome.min.css",
  ],
})
export class InstructionsComponent implements OnInit {
  candidateId: number;

  testInfo: any = TestCodeService;
  sectionInfo: any = [];
  candidateInfo: any = [];
  canId: number;
  uuidstring: string = "";
  testId: any;
  date = new Date();
  questionInformation: any = [];
  keyArray = [];
  sectionStartId: number = 0;
  isDisabled: boolean;
  possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  isElapsedTime: boolean = false;
  isResumeTest: boolean = false;
  isLoading: boolean = false;
  isValidTestCode: boolean = false;
  isUserExists: boolean = false;
  allTestDetails: TsCodeValid;
  isExternalDrive: any;
  isValidCandidate: boolean = false;
  errorMessage: string = "";
  randomString() {
    for (let i = 0; i < 6; i++) {
      this.uuidstring += this.possible.charAt(
        Math.floor(Math.random() * this.possible.length)
      );
    }
    return this.uuidstring;
  }
  uuid = new Date().getTime() + this.randomString();

  constructor(
    private questionBank: QuestionsService,
    private instructionInfo: InstructionsService,
    public testDetails: TestCodeService,
    private baseService: BaseService,
    private store: Store<any>,
    private router: Router,
    private location: LocationStrategy,
    private saveCandidate: CandidateInfoService
  ) {
    this.location.onPopState(() => {
      this.baseService.isBackButtonClicked = true;
      return false;
    });
  }

  async ngOnInit() {
    this.baseService.testCode = sessionStorage.getItem("testcode");
    if (!this.baseService.isExternalDrive) {
      await this.validateDetails();
      if (this.testDetails.allTestDetails.testCodeInfo.candidate == null) {
        let payload = {
          empId: Number(sessionStorage.getItem("empId")),
          name: this.baseService.userName,
          emailId: this.baseService.emailId,
          testCode: sessionStorage.getItem("testcode"),
          remainingTestMinutes:
            this.testDetails.allTestDetails.testCodeInfo.test
              .testDurationinMins,
          remainingTestSeconds: 59,
        };
        try {
          this.baseService
            .saveEmployeeDetails(payload, this.testDetails.isResumeTest)
            .subscribe((data) => {
              this.testDetails.allTestDetails.testCodeInfo.candidate =
                data.candidate;
              this.instructionInfo.canId = data.candidate.canId;
            });
        } catch (error) {
          console.log(error);
        }
      }
    }

    this.testDetails.allTestDetails$.subscribe((details) => {
      this.allTestDetails = details;
      this.testDetails.allTestDetails = this.allTestDetails;
    });
    this.testDetails.testDurationInMins =
      this.allTestDetails.testCodeInfo.test.testDurationinMins;

    if (this.allTestDetails.testCodeInfo) {
      this.testInfo = this.allTestDetails.testCodeInfo.test;
      this.store.dispatch({ type: UserActionTypes.GET_SECTION });
      this.store.select("section").subscribe((data) => {
        this.sectionInfo = data.section;
        this.questionBank.sectionInfo = this.sectionInfo;
        this.questionBank.testDuration =
          this.allTestDetails.testCodeInfo.test.testDurationinMins;
      });
      if (
        this.allTestDetails.testCodeInfo.candidate != null &&
        this.allTestDetails.testCodeInfo.candidate.isResumeTest
      ) {
        this.isDisabled = true;
      }
    }
  }

  async startTest() {
    if (this.testDetails.allTestDetails.testCodeInfo.candidate == null) {
      this.store.select("saveCandidate").subscribe((data) => {
        this.candidateId = data.saveCandidate
          ? data.saveCandidate.candidate.canId
          : null;
      });
    } else {
      this.candidateId =
        this.testDetails.allTestDetails.testCodeInfo.candidate.canId;
    }
    this.instructionInfo.candidateTestInfo = {
      canId: this.candidateId,
      empId: Number(sessionStorage.getItem("empId")),
      empName: this.baseService.userName,
      emailId: this.baseService.emailId,
      testId: this.testDetails.allTestDetails.testCodeInfo.test.testId,
      uuid: this.uuid,
      testCode: this.testDetails.allTestDetails.testCodeInfo.test.testCode,
      remainingTestMinutes:
        this.testDetails.allTestDetails.testCodeInfo.test.testDurationinMins -
        1,
      remainingTestSeconds: 59,
    };
    this.instructionInfo.canId = this.candidateId;
    this.instructionInfo.uuid = this.uuid;
    this.store.dispatch({ type: UserActionTypes.POST_START });
    this.store
      .select("startTest")
      .pipe(
        debounceTime(100),
        distinctUntilChanged(), // Ensures that only new values are emitted if they change
        switchMap((value) => {
          this.isLoading = value.loading;
          if (value && value.startTest != null) {
            this.keyArray = this.shuffleInPlace(Object.keys(value.startTest));
            this.questionBank.keySet = this.keyArray;
            this.questionBank.questionNumbers = this.keyArray.length;
            for (let i = 0; i < this.keyArray.length; i++) {
              this.questionBank.questionsInfo[i] =
                value.startTest[this.keyArray[i]];
            }

            return this.baseService
              .getCandidateRemainingTestTimeUrl(this.candidateId)
              .pipe(
                tap((data: any) => {
                  this.allTestDetails.testCodeInfo.candidate = data;
                }),
                switchMap(() => {
                  return this.router.navigate(["startTest"]);
                })
              );
          } else {
            return of(null);
          }
        })
      )
      .subscribe((navigationResult) => {});
  }

  shuffleInPlace<T>(array: T[]): T[] {
    // if it's 1 or 0 items, just return
    if (array.length <= 1) return array;

    // For each index in array
    for (let i = 0; i < array.length; i++) {
      // choose a random not-yet-placed item to place there
      // must be an item AFTER the current item, because the stuff
      // before has all already been placed
      const randomChoiceIndex = this.getRandom(array.length);

      // place our random choice in the spot by swapping
      [array[i], array[randomChoiceIndex]] = [
        array[randomChoiceIndex],
        array[i],
      ];
    }

    return array;
  }

  getRandom(max) {
    // returns random integer between 0 and max-1 inclusive.
    return Math.floor(Math.random() * max);
  }

  resumeTest() {
    this.getElapsedTime();
    this.instructionInfo.candidateTestInfo = {
      canId: this.allTestDetails.testCodeInfo.candidate.canId,
      testId: this.allTestDetails.testCodeInfo.test.testId,
      uuid: this.uuid,
      testCode: this.baseService.testCode,
    };
    this.instructionInfo.canId =
      this.allTestDetails.testCodeInfo.candidate.canId;

    this.instructionInfo.uuid = this.uuid;
    this.store.dispatch({ type: UserActionTypes.POST_RESUME });

    this.store.select("resumeTest").subscribe((data) => {
      if (data && data.loading) {
        this.isLoading = true;
        return;
      }
      this.isLoading = data.loading;
      if (
        (data.resumeTest && this.questionBank.elapsedTime) ||
        (data.resumeTest && this.isElapsedTime)
      ) {
        this.keyArray = this.shuffleInPlace(Object.keys(data.resumeTest));
        this.questionBank.keySet = this.keyArray;
        this.questionBank.questionNumbers = this.keyArray.length;
        if (data.resumeTest.length != 0) {
          for (let i = 0; i < this.keyArray.length; i++) {
            this.questionBank.questionsInfo[i] =
              data.resumeTest[this.keyArray[i]];
            this.questionBank.isResumeTest = true;
          }
        }
        this.router.navigate(["startTest"]);
      }
    });
  }
  getElapsedTime() {
    this.store.dispatch({ type: UserActionTypes.GET_ELAPSEDTIME });

    this.store.select("getElapsedTime").subscribe((data) => {
      this.isElapsedTime = true;
      // if (data.getElapsedTime) {
      //   this.questionBank.elapsedTime = data.getElapsedTime;
      // } else if (data.getElapsedTime == 0) {
      //   this.isElapsedTime = true;
      // }
    });
  }

  validateDetails(): Promise<void> {
    return new Promise((resolve) => {
      this.store.dispatch({ type: UserActionTypes.USER_LOGIN });
      this.store.select("userState").subscribe((data) => {
        this.allTestDetails = data;

        this.testDetails.allTestDetails = this.allTestDetails;

        if (data.testCodeInfo && data.testCodeInfo.status != null) {
          this.errorMessage = data.testCodeInfo.errorMessage;
          this.isValidCandidate = true;
          return;
        }

        // Step 5: Update BehaviorSubject with the new data
        this.testDetails.updateAllTestDetails(data);

        if (data.loading || !data.testCodeInfo) {
          // resolve(); // Early resolve if data is not yet ready
          return;
        }

        if (data.testCodeInfo.test) {
          this.testDetails.isLateralTest = data.testCodeInfo.test.isLateralTest;
          this.testDetails.testDurationInMins =
            data.testCodeInfo.test.testDurationinMins;
        }

        if (data.testCodeInfo.candidate != null) {
          this.testDetails.isResumeTest =
            data.testCodeInfo.candidate.isResumeTest;
          this.instructionInfo.canId = data.testCodeInfo.candidate.canId;
        }

        if (
          data.testCodeInfo.candidate &&
          data.testCodeInfo.candidate.isResumeTest
        ) {
          this.isDisabled = true;
        }

        resolve();
      });
    });
  }

  closePopup() {
    sessionStorage.clear();
    this.router.navigate([""]);
  }
}
