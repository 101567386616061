<div class="container-fluid" id="body">
  <div class="row">
    <div class="col-sm-2">
      <h6 class="alert alert-primary"><b>Hello, </b>{{ candidateName }}</h6>
    </div>
    <div class="col-sm-8" id="pagination">
      <span *ngFor="let question of arr2; index as i" style="padding-left: 2px">
        <button
          (click)="questionChange(question)"
          [ngClass]="{
            active: questionNumber == question,
            deactive: !(questionNumber == question),
            answered: i == arr3[i]
          }"
          [ngStyle]="{
            'background-color':
              answeredEarlier && questionNumber == question
                ? 'rgb(66,139,202)'
                : [ngClass]
          }"
          class="btn"
          style="width: 40px; margin-top: 1px; margin-bottom: 1px"
        >
          {{ question }}
        </button>
      </span>
    </div>
    <div class="col-sm-2 right" style="border-radius: 5px">
      <div id="clock">
        <div>
          <span [ngClass]="{ timeSafe: !timeDanger, timeUnSafe: timeDanger }">{{
            remainingMinutes
          }}</span>
          <div class="smallText">Minutes</div>
        </div>
        <div>
          <span [ngClass]="{ timeSafe: !timeDanger, timeUnSafe: timeDanger }">{{
            remainingSeconds
          }}</span>
          <div class="smallText">Seconds</div>
        </div>
      </div>
      <div>
        <button
          (click)="endTest()"
          style="margin-left: 30px"
          class="btn btn-danger"
        >
          End Test
        </button>
      </div>
    </div>
  </div>
  <div class="popup" *ngIf="isBoxOpened">
    <div class="popup-content">
      <div class="alert alert-warning">
        <b style="color: rgba(231, 96, 73, 0.986)" *ngIf="!isCancelActivated"
          >Warning!</b
        >
        {{ message }}
      </div>
      <div style="text-align: right">
        <button class="btn btn-primary" (click)="BoxClose()">OK</button>
        <button
          class="btn"
          (click)="Cancel()"
          *ngIf="isCancelActivated"
          style="margin-left: 10px"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
  <div class="row" style="margin-top: 10px">
    <div class="col-sm-2">
      <div
        *ngFor="let section of sectionInfo"
        style="background-color: #f1f1f1"
        class="list-group"
        style="border: 1px solid lightgray; border-radius: 5px"
      >
        <button
          (click)="sectionChange(section.sectionId)"
          class="list-group-item pointer"
          [ngClass]="{ active: section.sectionId == sectionId }"
        >
          {{ section.sectionName }}
        </button>
      </div>
    </div>
    <div class="col-sm-8 card" id="question">
      <span *ngFor="let questions of pagedQuestions" class="row">
        <!-- <b>{{questionNumber}}</b> -->
        <ul class="col-sm-11" style="margin-left: 20px">
          <div style="margin-top: 10px"></div>
          <div style="margin-left: 20px" [innerHtml]="questionText"></div>
          <div class="container" id="options">
            <div
              id="answer"
              [ngClass]="{
                answeredOption: selectedOption == 1 || selectedAnswer == 1
              }"
            >
              <input
                type="radio"
                name="answer"
                [(ngModel)]="selectedAnswer"
                (change)="checkedOption($event)"
                [value]="1"
                value="1"
              />&nbsp;<span [innerHtml]="option1"></span>
            </div>
            <div
              id="answer"
              [ngClass]="{
                answeredOption: selectedOption == 2 || selectedAnswer == 2
              }"
            >
              <input
                type="radio"
                name="answer"
                [(ngModel)]="selectedAnswer"
                (change)="checkedOption($event)"
                [value]="2"
                value="2"
              />&nbsp;<span [innerHtml]="option2"></span>
            </div>
            <div
              id="answer"
              [ngClass]="{
                answeredOption: selectedOption == 3 || selectedAnswer == 3
              }"
            >
              <input
                type="radio"
                name="answer"
                [(ngModel)]="selectedAnswer"
                (change)="checkedOption($event)"
                [value]="3"
                value="3"
              />&nbsp;<span [innerHtml]="option3"></span>
            </div>
            <div
              id="answer"
              [ngClass]="{
                answeredOption: selectedOption == 4 || selectedAnswer == 4
              }"
            >
              <input
                type="radio"
                name="answer"
                [(ngModel)]="selectedAnswer"
                (change)="checkedOption($event)"
                [value]="4"
                value="4"
              />&nbsp;<span [innerHtml]="option4"></span>
            </div>
          </div>
          <button
            class="btn btn-primary"
            id="clearButton"
            (click)="clear()"
            style="margin-left: 20px"
          >
            Clear
          </button>
        </ul>
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6"></div>
    <div class="col-sm-4 buttons">
      <footer>
        <span
          *ngIf="
            questionNumber == 1 && !(sectionId == this.sectionInfo[0].sectionId)
          "
        >
          <button
            (click)="sectionChange(sectionId, 'previous')"
            id="navButtons"
            class="btn btn-primary"
          >
            < Section
          </button>
        </span>
        <span *ngIf="!(questionNumber == 1)">
          <button
            (click)="questionChange(questionNumber, 'previousQuestion')"
            id="navButtons"
            class="btn btn-primary"
          >
            Previous
          </button>
        </span>

        <span style="padding-left: 2%" *ngIf="isNextButtonVisible">
          <button
            (click)="questionChange(questionNumber, 'nextQuestion')"
            id="navButtons"
            class="btn btn-primary"
          >
            Next
          </button>
        </span>
        <span
          style="padding-left: 2%"
          *ngIf="
            (questionNumber == endPage &&
              !(
                sectionId ==
                this.sectionInfo[this.sectionInfo.length - 1].sectionId
              )) ||
            sectionQuestionsLength == 1
          "
        >
          <button
            *ngIf="!isLastSection"
            (click)="sectionChange(sectionId, 'next')"
            id="navButtons"
            class="btn btn-primary"
          >
            Section >
          </button>
        </span>
        <span *ngIf="isSaveAndEndTestButtonsVisible">
          <span style="padding-left: 2%">
            <button
              class="btn btn-primary"
              id="navButtons"
              (click)="questionChange(questionNumber)"
            >
              Save
            </button>
          </span>
          <span style="padding-left: 2%">
            <button class="btn btn-danger" id="navButtons" (click)="endTest()">
              End Test
            </button>
          </span>
        </span>
      </footer>
    </div>
  </div>
</div>
<div
  *ngIf="
    isFullScreenExit &&
    warningCount == 1 &&
    remainingSeconds != 0 &&
    remainingMinutes != 0
  "
  class="popup-overlay"
>
  <div class="warning-popup">
    <h3>Warning: Test Space Violation</h3>
    <p>
      You are not allowed to leave the test space; if you attempt this again,
      the test will end automatically.
    </p>
    <button class="btn btn-primary" (click)="enterFullscreen()">OK</button>
  </div>
</div>
<div
  *ngIf="remainingSeconds == 0 && remainingMinutes == 0"
  class="popup-overlay"
>
  <div class="warning-popup">
    <h3>Time up</h3>
    <p>Your Time is up, Your test will end.</p>
    <button class="btn btn-primary" (click)="endTimer()">OK</button>
  </div>
</div>
