<div class="container fixed allContent">
  <div class="card">
    <div class="container" id="body">
      <form #validateTest="ngForm">
        <div *ngIf="!isExternalDrive">
          <label for="tscode"> Test Code </label>
          <input
            type="text"
            id="tscode"
            name="tscode"
            class="form-control"
            ngModel
            required
            (blur)="warning()"
            (focus)="warning()"
            (input)="testCodeChange($event)"
            [(ngModel)]="testCode"
          />
          <div *ngIf="errorMessage && testCode" class="alert alert-warning">
            {{ errorMessage }}
          </div>
        </div>
        <div *ngIf="isExternalDrive">
          <label for="email"> Email </label>
          <input
            type="email"
            name="email"
            id="email"
            class="form-control"
            [(ngModel)]="email"
            (blur)="warning()"
            (focus)="warning()"
            ngModel
            required
            email
            #umail="ngModel"
          />

          <div
            *ngIf="umail.errors && umail.touched && umail.dirty"
            class="alert alert-warning"
          >
            Invalid Email
          </div>
        </div>

        <div *ngIf="auth">
          <div class="alert alert-warning">Server Side Problem</div>
        </div>
        <div style="text-align: right" *ngIf="!isLoading">
          <button
            class="btn btn-primary"
            id="navButtons"
            (click)="!isExternalDrive ? validateTestCode() : validateDetails()"
            style="margin-top: 25px; margin-bottom: 25px"
            [disabled]="validateTest.invalid"
            type="submit"
          >
            Next
          </button>
        </div>

        <div style="text-align: right" *ngIf="isLoading">
          <button
            class="btn btn-primary"
            [disabled]="isLoading"
            style="margin-top: 25px; margin-bottom: 25px"
          >
            <i class="fa fa-spinner fa-spin"></i>Loading
          </button>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="isUserExists" class="alert alert-warning userExists">
    {{ errorMessage }}
  </div>
</div>
