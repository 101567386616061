import { Injectable } from '@angular/core';
import { BaseService } from './baseService';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EndTestService {

  endTestInfo:any={}
  constructor( private baseService: BaseService) { }
  endTest():Observable<any[]>{
    return this.baseService.endTest(this.endTestInfo)
  }
}
