import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Urls } from "../urls";
import { EmailValid } from "../emailValid";
import { Observable } from "rxjs";
import { TsCodeValid } from "../tsCodeValid";
import { Injectable } from "@angular/core";
import { GovernmentIdTypes } from "../governmentIdTypes";
import { map, catchError } from "rxjs/operators";
import { Http } from "@angular/http";
@Injectable({
  providedIn: "root",
})
export class BaseService {
  isGuardActivated: boolean = false;
  isAdminLoggedIn: boolean = false;
  isBackButtonClicked: boolean = false;
  isTestStarted: boolean;
  candidateName: string;
  newTabOpened: boolean = false;
  clientid: string = "onlinetest-client";
  clientsecret: string = "gXrLKJDSB(676@DSkjscvnqwe";
  testCode: string = sessionStorage.getItem("testcode");
  emailId: string = "";
  access_token = "";
  userName: string;
  isUserLoggedIn: boolean;
  isExternalDrive: boolean;
  constructor(
    private http: HttpClient,
    private urls: Urls,
    private _http: Http
  ) {}
  getCandidateDetails(uemail: string): Observable<EmailValid[]> {
    return this.http
      .get<EmailValid[]>(this.urls.emailIdUrl + uemail)
      .pipe(
        map((data) => {
          return data;
        })
      )
      .pipe(
        catchError((error) => {
          throw error;
        })
      );
  }

  public getApplications(emailId): Observable<any> {
    // let headers = new HttpHeaders({
    //   "Content-Type": "application/json",
    //   Authorization: "Bearer " + this.access_token,
    // });
    return this.http.get(this.urls.getApplicationByEmpId + emailId);
  }

  validateTestCode(testCode: string): Observable<TsCodeValid[]> {
    return this.http.get<TsCodeValid[]>(
      this.urls.validateTestCodeUrl(testCode)
    );
  }

  getTestDetails(
    testCode: string,
    emailId: string,
    isExternal: boolean
  ): Observable<TsCodeValid[]> {
    return this.http.get<TsCodeValid[]>(
      this.urls.validateDetails(testCode, emailId, isExternal)
    );
  }
  getGenderTypes(): Observable<any[]> {
    return this.http.get<any[]>(this.urls.genderUrl);
  }
  getGovIdTypes(): Observable<GovernmentIdTypes[]> {
    return this.http.get<GovernmentIdTypes[]>(this.urls.govIdTypesUrl);
  }
  saveCandidateDetails(
    candidateInfo: any[],
    isResumeTest: boolean
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.urls.saveCandUrl + !isResumeTest,
      candidateInfo
    );
  }
  postCandidateTestInfo(candidateInfo: any): Observable<any[]> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.access_token,
    });
    return this.http.post<any[]>(this.urls.startTestUrl, candidateInfo);
  }
  getSections(testId: number): Observable<any[]> {
    return this.http.get<any[]>(this.urls.sectionsUrl + testId);
  }
  postResumeCandidateTestInfo(candidateInfo: any): Observable<any[]> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.access_token,
    });
    const data = {
      ...candidateInfo,
      testCode: this.testCode,
    };
    return this.http.post<any[]>(this.urls.resumeTestUrl, data, {
      headers: headers,
    });
  }
  getElapsedTime(
    testId: number,
    candidateId: number,
    testCode: string
  ): Observable<any[]> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.access_token,
    });

    return this.http.get<any[]>(
      this.urls.getElaspsedTimeUrl(testId, candidateId, this.testCode),
      {
        headers: headers,
      }
    );
  }
  saveAnswer(saveAnswerInfo: any[]): Observable<any[]> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.access_token,
    });
    return this.http.post<any[]>(this.urls.saveAnswer, saveAnswerInfo, {
      headers: headers,
    });
  }
  clearAnswer(clearAnswerInfo: any[]): Observable<any[]> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.access_token,
    });
    return this.http.post<any[]>(this.urls.clearAnswer, clearAnswerInfo, {
      headers: headers,
    });
  }
  endTest(endTestInfo: any[]): Observable<any[]> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.access_token,
    });
    return this.http.post<any[]>(this.urls.endTest, endTestInfo, {
      headers: headers,
    });
  }
  updateSummary(updateInfo: any[]): Observable<any[]> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.access_token,
    });
    return this.http.post<any[]>(this.urls.updateSummary, updateInfo, {
      headers: headers,
    });
  }
  public getEmployeeRolesByEmail(emailId: string): Observable<any> {
    return this.http.get(this.urls.getEmployeeRolesByEmail + emailId);
  }

  public getRefreshToken(refreshToken: any): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http.post<any[]>(this.urls.refreshTokenUrl, refreshToken, {
      headers,
    });
  }

  saveEmployeeDetails(
    candidateInfo: any,
    isResumeTest: boolean
  ): Observable<any> {
    return this.http.post<any>(
      this.urls.saveEmployeedUrl + !isResumeTest,
      candidateInfo
    );
  }
  getCandidateRemainingTestTimeUrl(candidateId: number): Observable<any> {
    return this.http.get<any[]>(
      this.urls.getCandidateRemainingTestTimeUrl + candidateId
    );
  }
}
