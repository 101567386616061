import { Component, OnInit, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { TestCodeService } from "../services/test-code.service";
import { Store } from "@ngrx/store";
import { UserActionTypes } from "../onlineTestReducer";
import { AppStore } from "../onlineStore";
import { TsCodeValid } from "../tsCodeValid";
import { BaseService } from "../services/baseService";
import { LocationStrategy } from "@angular/common";
import { ComponenetCanDeactivate } from "../routingGuards";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Urls } from "../urls";
import { OAuthService } from "angular-oauth2-oidc";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-test-code",
  templateUrl: "./test-code.component.html",
  styleUrls: [
    "./test-code.component.css",
    "../commonCss.css",
    "../../assets/font-awesome/css/font-awesome.min.css",
  ],
})
export class TestCodeComponent implements OnInit, ComponenetCanDeactivate {
  user: any;
  emailId: any;
  userName: any;
  isExternalDrive: boolean;
  errorMessage: string;
  constructor(
    private router: Router,
    private testDetails: TestCodeService,
    private store: Store<any>,
    private baseService: BaseService,
    private location: LocationStrategy,
    private http: HttpClient,
    private urls: Urls,
    private oauthService: OAuthService
  ) {
    this.location.onPopState(() => {
      this.baseService.isBackButtonClicked = true;
      return false;
    });
    this.http.get(this.urls.certificateUrl).subscribe(
      (data) => {},
      (err) => {
        if (err.status == 200) {
        } else if (err.status >= 400 && err.status < 500) {
        } else if (err.status >= 500 && err.status < 600) {
        } else {
          if (navigator.userAgent.indexOf("Firefox") != -1) {
            this.router.navigate(["certificateVerification"]);
          }
        }
      }
    );
  }
  dataUrl: any;
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    return true;
  }
  ngOnInit() {}
  isResumeTest: boolean = false;
  testCode: string;
  email: string;
  isLateralTest: boolean;
  allTestDetails: TsCodeValid;
  testInfo: any = [];
  candInfo: any = [];
  error: string;
  appInfo: AppStore;
  arr: boolean[] = [false];
  isValidTestCode: boolean = false;
  isUserExists: boolean = false;
  isValidUser: boolean = false;

  message: string;
  auth: boolean = false;
  isLoading: boolean = false;

  validateDetails() {
    sessionStorage.setItem("emailId", this.email);
    this.testDetails.emailId = this.email;
    this.testDetails.testCode = this.testCode;
    this.testDetails.isExternalDrive = Boolean(
      sessionStorage.getItem("isExternalDrive")
    );
    this.baseService.testCode = sessionStorage.getItem("testCode");
    this.baseService.emailId = sessionStorage.getItem("emailId");
    this.baseService.isExternalDrive = Boolean(
      sessionStorage.getItem("isExternalDrive")
    );
    this.store.dispatch({ type: UserActionTypes.USER_LOGIN });
    this.store.select("userState").subscribe((data) => {
      if (!data.status) {
        this.allTestDetails = data;
        this.testDetails.allTestDetails = this.allTestDetails;
        this.testDetails.updateAllTestDetails(data);
        if (data.loading || !data.testCodeInfo) {
          return;
        }
        if (data.testCodeInfo && data.testCodeInfo.status != null) {
          this.errorMessage = data.testCodeInfo.errorMessage;
          this.isUserExists = true;
          return;
        }
        if (data.testCodeInfo.test) {
          this.testDetails.isLateralTest =
            this.allTestDetails.testCodeInfo.test.lateralTest;
        }
        if (this.allTestDetails.testCodeInfo.candidate != null) {
          this.testDetails.isResumeTest =
            this.allTestDetails.testCodeInfo.candidate.isResumeTest;
        }
        this.isLoading = data.loading;
        if (data.error) {
          this.isValidTestCode = false;
          this.isUserExists = false;
          if (data.error.status == 404) {
            this.isValidTestCode = true;
          } else if (data.error.status == 500) {
            this.isUserExists = true;
          }
        }
        if (
          this.allTestDetails &&
          this.allTestDetails.testCodeInfo.jwtResponse
        ) {
          sessionStorage.setItem(
            "access_token",
            this.allTestDetails.testCodeInfo.jwtResponse.token
          );
          sessionStorage.setItem(
            "refresh_token",
            this.allTestDetails.testCodeInfo.jwtResponse.refreshToken
          );
        }
        this.router.navigate(["candidateForm"]);
      } else {
        this.errorMessage = data.errorMessage;
      }
    });
  }

  validateTestCode() {
    this.baseService.testCode = this.testCode;
    this.testDetails.testCode = this.testCode;
    sessionStorage.setItem("testcode", this.testCode);
    this.baseService.validateTestCode(this.baseService.testCode).subscribe(
      (response: any) => {
        if (!response.status) {
          sessionStorage.setItem("isExternalDrive", response.isExternalDrive);
          if (response.isExternalDrive) {
            this.isExternalDrive = true;
            this.baseService.isExternalDrive = response.isExternalDrive;
          } else {
            this.testDetails.configureMicroSoftLogin();
          }
        } else {
          this.errorMessage = response.errorMessage;
        }
      },
      (err) => {}
    );
  }

  testCodeChange(event) {
    if (event.data !== this.testCode) {
      this.errorMessage = "";
    }
  }

  warning() {
    this.isValidTestCode = false;
    this.isUserExists = false;
  }
  handleTokenStorage() {
    if (!sessionStorage.getItem("access_token")) {
      if (!this.oauthService.hasValidAccessToken()) {
        this.oauthService.events
          .pipe(filter((e: any) => e.type === "token_received"))
          .subscribe(() => {
            this.setTokenDetails(); // Set token and user details on receiving token
            this.setDataInSessionStorage();
          });
      } else {
        this.setTokenDetails();
        this.setDataInSessionStorage();
      }
    } else {
      if (sessionStorage.getItem("access_token")) {
        this.baseService.access_token = sessionStorage.getItem("access_token");
      }
    }
  }

  setTokenDetails() {
    if (this.oauthService.hasValidAccessToken()) {
      this.baseService.access_token = this.oauthService.getAccessToken();

      this.user = this.getUserInfo();

      this.baseService.userName = this.user.name;

      this.emailId = this.user.email ? this.user.email : this.user.unique_name;

      this.userName = this.user.name;
    }
  }

  setDataInSessionStorage() {
    sessionStorage.setItem("access_token", this.oauthService.getAccessToken());
  }
  getUserInfo() {
    const token = this.oauthService.getAccessToken();

    let payload;

    if (token) {
      payload = token.split(".")[1];

      payload = window.atob(payload);

      return JSON.parse(payload);
    } else {
      return null;
    }
  }
  closePopup() {
    sessionStorage.clear();
    this.router.navigate([""]);
  }
}
