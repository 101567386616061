import { ToastrService } from "ngx-toastr";

export class ErrorsHandling {

    handleErrors(error: any, header: string, toastService: ToastrService) {
        switch (error.status) {
            case 400:
                if (error._body != null) {
                    let err = JSON.parse(error._body);
                    if (err.error_description = "Bad Credentials") {
                        toastService.error('Invalid Credentials. Please enter valid credentials', 'Sign In Failed')
                    } else {
                        toastService.error('Something went wrong. Please try again ', header)
                    }
                } else {
                    toastService.error('Something went wrong. Please try again ', header)
                }
                break;
            case 401:
                if (error.error.error == 'invalid_token') {
                    toastService.error('Your session has been completed. Please login again', header);
                } else {
                    toastService.error('Something went wrong. Please try again ', header)
                }
                break;
            default: toastService.error('Something went wrong. Please try again ', header)
        }
    }
}