import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AppComponent } from "./app.component";
import { TestCodeComponent } from "./test-code/test-code.component";
import { CandidateFormComponent } from "./candidate-form/candidate-form.component";
import { HttpModule } from "@angular/http";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { routing } from "./routes";
import { RouterModule } from "@angular/router";
import { Urls } from "./urls";
import { InstructionsComponent } from "./instructions/instructions.component";
import { QuestionsService } from "./services/questions.service";
import { QuestionsComponent } from "./questions/questions.component";
import { TestCodeService } from "./services/test-code.service";
import { BaseService } from "./services/baseService";
import { StoreModule } from "@ngrx/store";
import {
  userReducer,
  genderDetails,
  govtIdDetails,
  getSectionDetails,
  saveCandidateDetails,
  startTestDetails,
  resumeTestDetails,
  getElapsedTime,
  saveAnswerDetails,
  clearAnswerDetails,
  endTestDetails,
  updateSummaryDetails,
  adminLoginDetails,
  userCreationDetails,
  getTestReports,
  getAllSectionDetails,
  getTestDetails,
} from "./onlineTestReducer";
import { EffectsModule } from "@ngrx/effects";
import { UserEffects } from "./onlineTestEffects";
import { AllowedForTestComponent } from "./allowed-for-test/allowed-for-test.component";
import { PageService } from "./services/page.service";
import {
  DatePipe,
} from "@angular/common";
import { RoutingGuard, CanDeactivateTeam, BlockingPage } from "./routingGuards";
import { CertificateCheckingComponent } from "./certificate-checking/certificate-checking.component";
import { UiSwitchModule } from "ngx-toggle-switch";
import { AgGridModule } from "ag-grid-angular";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ErrorsHandling } from "./utils/errors/errorshandling";
import { NgxPaginationModule } from "ngx-pagination";
import { OAuthModule } from "angular-oauth2-oidc";
import { AuthInterceptor } from "./utils/interceptors";
import { AuthService } from "./utils/authService";
import { MatSnackBarModule } from "@angular/material";
import { MatButtonModule, MatFormFieldModule, MatInputModule, MatSelectModule } from "@angular/material";

@NgModule({
  declarations: [
    AppComponent,
    TestCodeComponent,
    CandidateFormComponent,
    InstructionsComponent,
    QuestionsComponent,
    AllowedForTestComponent,
    CertificateCheckingComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    routing,
    AgGridModule.withComponents([]),
    StoreModule.forRoot({
      userState: userReducer,
      gender: genderDetails,
      govtId: govtIdDetails,
      saveCandidate: saveCandidateDetails,
      section: getSectionDetails,
      startTest: startTestDetails,
      resumeTest: resumeTestDetails,
      getElapsedTime: getElapsedTime,
      saveAnswer: saveAnswerDetails,
      clearAnswer: clearAnswerDetails,
      endTest: endTestDetails,
      updateSummary: updateSummaryDetails,
      adminLogin: adminLoginDetails,
      userDetails: userCreationDetails,
      testDetails: getTestReports,
      sectionDetails: getAllSectionDetails,
      allTestDetails: getTestDetails,
    }),
    EffectsModule.forRoot([UserEffects]),
    UiSwitchModule,
    ToastrModule.forRoot(),
    NgxPaginationModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: ["http://localhost:8998/"],
        sendAccessToken: true,
      },
    }),
    MatSnackBarModule,
  ],

  providers: [
    Urls,
    QuestionsService,
    TestCodeService,
    BaseService,
    PageService,
    QuestionsService,
    DatePipe,
    RoutingGuard,
    CanDeactivateTeam,
    BlockingPage,
    QuestionsComponent,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },

    ErrorsHandling,
  ],
  bootstrap: [AppComponent],
  exports: [RouterModule],
})
export class AppModule {
}
