<div class="container-fluid">
  <div class="row">
    <div class="col-sm-3" style="margin-left: 23px">
      <img
        class="img-fluid float-left"
        src="assets/images/compass_competency_assessment logo.JPG"
        alt="Candidate _Evaluation_Logo"
        width="240"
        height="120"
        style="margin-top: 11px"
      />
    </div>
    <div class="col-sm-6" style="margin-left: 70px"></div>
    <diV class="col-sm-2">
      <img
        class="img-fluid fixed-left col-sm-12"
        src="assets/images/cognine_logo2.png"
        alt="Cognine-logo"
        width="200"
        height="80"
        style="margin-top: 8px"
      />
    </diV>
  </div>
  <div class="col-sm-12" style="margin-bottom: 75px; margin-top: 1px">
    <router-outlet></router-outlet>
  </div>
  <div id="bottomLine" class="fixed-bottom">
    <div>
      <img
        src="assets/images/cognine_bottom_line.png"
        class="col-sm-12"
        height="3px"
      />
    </div>
    <div class="row">
      <h6 class="col-sm-2" style="margin-bottom: 10px; padding-left: 30px">
        Cognine Technologies
      </h6>
      <h6
        class="col-sm-10"
        style="text-align: right; float: right; padding-right: 30px"
      >
        ©{{currentYear}} All Rights Reserved.
      </h6>
    </div>
  </div>
</div>
