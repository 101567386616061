import { Injectable } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";
import { TestCodeService } from "./services/test-code.service";
import {
  UserActionTypes,
  LoginAction,
  GenderAction,
  GovtIdAction,
  SaveCandidate,
  SaveCandidateAction,
  saveAnswerDetails,
  AdminLogin,
  adminLoginDetails,
  userCreationDetails,
} from "./onlineTestReducer";
import { map, switchMap, catchError, tap } from "rxjs/operators";
import { BaseService } from "./services/baseService";
import { CandidateInfoService } from "./services/candidate-info.service";
import { InstructionsService } from "./services/instructions.service";
import { of } from "rxjs";
import { SaveAnswerService } from "./services/save-answer.service";
import { EndTestService } from "./services/end-test.service";
import { ToasterService } from "./services/toaster.service";
import { Router } from "@angular/router";
@Injectable()
export class UserEffects {
  constructor(
    private actions: Actions,
    private testInfo: TestCodeService,
    private baseService: BaseService,
    private savecandidateInfo: CandidateInfoService,
    private testDetails: TestCodeService,
    private instructionInfo: InstructionsService,
    private saveAnswerService: SaveAnswerService,
    private endTestService: EndTestService,
    private toasterService: ToasterService,
    private router: Router
  ) {}

  @Effect()
  login = this.actions
    .ofType(UserActionTypes.USER_LOGIN)
    .pipe(map((action: LoginAction) => action))
    .pipe(
      switchMap((action) =>
        this.testInfo
          .getTestDetails()
          .pipe(
            map((data) => ({
              type: UserActionTypes.USER_LOGIN_SUCCESS,
              testCodeInfo: data,
            }))
          )
          // .pipe(catchError(err => err.code === 404 ? throwError("Not Found") : throwError(err)))));
          .pipe(
            catchError((err) =>
              of({ type: UserActionTypes.USER_LOGIN_ERROR, error: err })
            )
          )
      )
    );
  @Effect()
  genderdetails = this.actions
    .ofType(UserActionTypes.GET_GENDER)
    .pipe(map((action: GenderAction) => action))
    .pipe(
      switchMap((action) =>
        this.baseService
          .getGenderTypes()
          .pipe(
            map((data) => ({
              type: UserActionTypes.GET_GENDER_SUCCESS,
              gender: data,
            }))
          )
          .pipe(
            catchError((err) =>
              of({ type: UserActionTypes.GET_GENDER_ERROR, error: "Error" })
            )
          )
      )
    );

  @Effect()
  govIdDetails = this.actions
    .ofType(UserActionTypes.GET_GOVTID)
    .pipe(map((action: GovtIdAction) => action))
    .pipe(
      switchMap((action) =>
        this.baseService
          .getGovIdTypes()
          .pipe(
            map((data) => ({
              type: UserActionTypes.GET_GOVTID_SUCCESS,
              govtId: data,
            }))
          )
          .pipe(
            catchError((err) =>
              of({ type: UserActionTypes.GET_GOVTID_ERROR, error: "Error" })
            )
          )
      )
    );

  @Effect()
  saveCandidateDetails = this.actions
    .ofType(UserActionTypes.SAVE_CANDIDATE)
    .pipe(map((action: SaveCandidateAction) => action))
    .pipe(
      switchMap((action) =>
        this.savecandidateInfo
          .postCandidateDetails()
          .pipe(
            map((data) => ({
              type: UserActionTypes.SAVE_CANDIDATE_SUCCESS,
              saveCandidate: data,
            }))
          )
          .pipe(
            catchError((err) =>
              of({ type: UserActionTypes.SAVE_CANDIDATE_ERROR, error: "Error" })
            )
          )
      )
    );

  @Effect()
  sectionDetails = this.actions
    .ofType(UserActionTypes.GET_SECTION)
    // .pipe(map((action: LoginAction) => action))
    .pipe(
      switchMap((action) =>
        this.baseService
          .getSections(this.testDetails.allTestDetails.testCodeInfo.test.testId)
          .pipe(
            map((data) => ({
              type: UserActionTypes.GET_SECTION_SUCCESS,
              section: data,
            }))
          )
          .pipe(
            catchError((err) =>
              of({ type: UserActionTypes.GET_SECTION_ERROR, error: "Error" })
            )
          )
      )
    );

  @Effect()
  startTestDetails = this.actions
    .ofType(UserActionTypes.POST_START)
    // .pipe(map((action: LoginAction) => action))
    .pipe(
      switchMap((action) =>
        this.instructionInfo
          .postCandidateTestInfo()
          .pipe(
            map((data) => ({
              type: UserActionTypes.POST_START_SUCCESS,
              startTest: data,
            }))
          )
          .pipe(
            catchError((err) =>
              of({ type: UserActionTypes.POST_START_ERROR, error: "Error" })
            )
          )
      )
    );

  @Effect()
  resumeTestDetails = this.actions
    .ofType(UserActionTypes.POST_RESUME)
    // .pipe(map((action: LoginAction) => action))
    .pipe(
      switchMap((action) =>
        this.instructionInfo
          .postResumeCandidateTestInfo()
          .pipe(
            map((data) => ({
              type: UserActionTypes.POST_RESUME_SUCCESS,
              resumeTest: data,
            }))
          )
          .pipe(
            catchError((err) =>
              of({ type: UserActionTypes.POST_RESUME_ERROR, error: "Error" })
            )
          )
      )
    );

  @Effect()
  getElapsedTimeDetails = this.actions
    .ofType(UserActionTypes.GET_ELAPSEDTIME)
    .pipe(
      switchMap((action) =>
        this.baseService
          .getElapsedTime(
            this.testDetails.allTestDetails.testCodeInfo.test.testId,
            this.instructionInfo.canId,
            this.baseService.testCode
          )
          .pipe(
            map((data) => ({
              type: UserActionTypes.GET_ELAPSEDTIME_SUCCESS,
              getElapsedTime: data,
            }))
          )
          .pipe(
            catchError((err) =>
              of({
                type: UserActionTypes.GET_ELAPSEDTIME_ERROR,
                error: "Error",
              })
            )
          )
      )
    );

  @Effect()
  saveAnswerDetails = this.actions.ofType(UserActionTypes.SAVE_ANSWER).pipe(
    tap(() => {
      // Show success toaster here
      this.toasterService.showSuccess("Answer saved successfully!");
    }),
    switchMap((action) =>
      this.saveAnswerService
        .saveAnswer()
        .pipe(
          map((data) => ({
            type: UserActionTypes.SAVE_ANSWER_SUCCESS,
            saveAnswer: data,
          }))
        )
        .pipe(
          catchError((err) => {
            this.toasterService.showError("Failed to save answer.");

            return of({
              type: UserActionTypes.SAVE_ANSWER_ERROR,
              error: "Error",
            });
          })
        )
    )
  );

  @Effect()
  clearAnswerDetails = this.actions.ofType(UserActionTypes.CLEAR_ANSWER).pipe(
    switchMap((action) =>
      this.saveAnswerService
        .clearAnswer()
        .pipe(
          map((data) => ({
            type: UserActionTypes.CLEAR_ANSWER_SUCCESS,
            clearAnswer: data,
          }))
        )
        .pipe(
          catchError((err) =>
            of({ type: UserActionTypes.CLEAR_ANSWER_ERROR, error: "Error" })
          )
        )
    )
  );

  @Effect()
  endTestDetails = this.actions.ofType(UserActionTypes.END_TEST).pipe(
    switchMap((action) =>
      this.endTestService
        .endTest()
        .pipe(
          map((data) => {
            // Show a success toaster notification
            this.toasterService.showSuccess("Test ended successfully!");
            // Navigate to the assessment end page
            sessionStorage.clear();
            this.router.navigate(["/assessmentEnd"]);
            return {
              type: UserActionTypes.END_TEST_SUCCESS,
              endTest: data,
            };
          })
        )
        .pipe(
          catchError((err) => {
            this.toasterService.showError("Failed to End Test.");

            return of({ type: UserActionTypes.END_TEST_ERROR, error: "Error" });
          })
        )
    )
  );

  @Effect()
  updateSummaryDetails = this.actions
    .ofType(UserActionTypes.UPDATE_SUMMARY)
    .pipe(
      switchMap((action) =>
        this.saveAnswerService
          .updateSummary()
          .pipe(
            map((data) => ({
              type: UserActionTypes.UPDATE_SUMMARY_SUCCESS,
              updateSummary: data,
            }))
          )
          .pipe(
            catchError((err) =>
              of({ type: UserActionTypes.UPDATE_SUMMARY_ERROR, error: err })
            )
          )
      )
    );
}
