import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanDeactivate,
} from "@angular/router";
import { Observable } from "rxjs";
import { BaseService } from "./services/baseService";
import { CandidateFormComponent } from "./candidate-form/candidate-form.component";
import { CandidateInfoService } from "./services/candidate-info.service";
import { TestCodeComponent } from "./test-code/test-code.component";

@Injectable({
  providedIn: "root",
})
export class RoutingGuard implements CanActivate {
  constructor(private router: Router, private baseService: BaseService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.baseService.isGuardActivated) {
      this.baseService.getRefreshToken(sessionStorage.getItem("refresh_token"));
      return true; // Allow navigation if the guard is not activated
    } else {
      this.router.navigate(["/"]); // Redirect or block navigation
      return false;
    }
  }
}

export interface ComponenetCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}
@Injectable()
export class BlockingPage implements CanDeactivate<ComponenetCanDeactivate> {
  canDeactivate(
    component: ComponenetCanDeactivate
  ): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first

    // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
    // when navigating away from your angular app, the browser will show a generic warning message
    // see http://stackoverflow.com/a/42207299/7307355
    //   confirm('WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.');
    return component.canDeactivate() ? true : false;
  }
}
@Injectable()
export class CanDeactivateTeam implements CanDeactivate<TestCodeComponent> {
  constructor(
    private candidateService: CandidateInfoService,
    private baseService: BaseService
  ) {}

  canDeactivate(
    component: TestCodeComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.baseService.isBackButtonClicked) {
      this.baseService.isBackButtonClicked = false;
      history.pushState(null, null, location.href);
      return false;
    } else {
      return true;
    }
  }
}
