// import { range } from "rxjs";

export class PageService {
    pageSize: number = 1
    getPageParameters(totalNumberOfQuestions: number, currentPage: number=1, endPage: number) {

        let totalNumberOfPages = Math.ceil(totalNumberOfQuestions / this.pageSize);
        let startIndex = (currentPage) * this.pageSize;
        let endIndex = startIndex + this.pageSize;
        let isPreviousButtonDisabled: boolean = false;
        let isNextButtonDisabled: boolean = false;
        if (currentPage < 1) {
            currentPage = 1;
        }
        if (currentPage > totalNumberOfPages) {
            currentPage = totalNumberOfPages;
        }

        if (currentPage == endPage) {
            isNextButtonDisabled = true;
        }

        return {
            totalNumberOfPages: totalNumberOfPages,
            currentPage: currentPage,
            pageSize: this.pageSize,
            startIndex: startIndex,
            endIndex: endIndex,
            isPreviousButtonDisabled: isPreviousButtonDisabled,
            isNextButtonDisabled: isNextButtonDisabled
        }

    }
}