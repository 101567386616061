import { Component } from "@angular/core";
import { BaseService } from "./services/baseService";
import { OAuthService } from "angular-oauth2-oidc";
import { authConfig } from "./utils/errors/authConfiguration";
import { Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { TestCodeService } from "./services/test-code.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "app";
  isUserLoggedIn: any;
  user: any;
  emailId: string = "";
  userName: string = "";
  empId: any;
  rolesList: any;
  currentYear: number = new Date().getFullYear();

  constructor(
    private router: Router,
    private oauthService: OAuthService,
    private baseService: BaseService,
    private testCodeService: TestCodeService
  ) {
    this.oauthService.configure(authConfig);
  }

  ngOnInit() {
    sessionStorage.clear();
    this.router.navigate(["/"]);
    if (!sessionStorage.getItem("access_token")) {
      this.baseService.isUserLoggedIn = false;
      this.baseService.testCode = sessionStorage.getItem("testcode");
      this.isUserLoggedIn = false;
      this.oauthService
        .loadDiscoveryDocumentAndTryLogin()
        .then(() => {
          if (!this.oauthService.hasValidAccessToken()) {
            this.oauthService.events
              .pipe(filter((e: any) => e.type === "token_received"))
              .subscribe(() => {
                this.setTokenDetails();
              });
          } else {
            this.setTokenDetails();
          }
        })
        .catch((err) => {
          console.error("Error during OAuth initialization:", err);
        });
    } else {
      this.baseService.testCode = sessionStorage.getItem("testcode");
      this.baseService.emailId = sessionStorage.getItem("emailId");
      this.baseService.access_token = sessionStorage.getItem("access_token");
      this.isUserLoggedIn = true;
      this.baseService.isUserLoggedIn = true;
      this.setTokenDetails();
      this.router.navigate(["instructions"]);
    }
  }

  async setTokenDetails() {
    if (this.oauthService.hasValidAccessToken()) {
      this.baseService.access_token = this.oauthService.getAccessToken();

      this.user = this.getUserInfo();
      this.baseService.userName = this.user.name;
      sessionStorage.setItem("name", this.user.name);
      if (sessionStorage.getItem("isExternalDrive") == "true") {
        this.emailId = this.user.sub;
      } else {
        this.emailId = this.user.unique_name;
      }
      sessionStorage.setItem("emailId", this.emailId);
      this.userName = this.user.name;
      if (sessionStorage.getItem("isExternalDrive") == "false") {
        this.rolesList = await this.getEmployeeRoles();
      }
      this.testCodeService.emailId = this.emailId;
      this.testCodeService.testCode = this.baseService.testCode;

      this.baseService.emailId = this.emailId;
      this.router.navigate(["instructions"]);
    }
  }
  setDataInSessionStorage() {
    sessionStorage.setItem("access_token", this.oauthService.getAccessToken());
    sessionStorage.setItem("emailId", this.emailId);
    sessionStorage.setItem("empId", this.rolesList[0].employeeId);
  }
  getUserInfo() {
    const token = this.oauthService.getAccessToken();

    let payload;

    if (token) {
      payload = token.split(".")[1];

      payload = window.atob(payload);

      return JSON.parse(payload);
    } else {
      return null;
    }
  }
  getEmployeeRoles() {
    try {
      this.baseService.getEmployeeRolesByEmail(this.emailId).subscribe(
        (rolesList) => {
          if (rolesList) {
            this.rolesList = rolesList;
            this.setDataInSessionStorage();
          } else {
          }
        },
        (error) => {
          console.error("Error fetching employee roles", error);
        }
      );
    } catch (error) {
      console.error("Error in getEmployeeRoles", error);
    }
  }
}
