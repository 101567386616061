import { OAuthService } from "angular-oauth2-oidc";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { TsCodeValid } from "../tsCodeValid";
import { BaseService } from "./baseService";
import { authConfig } from "../utils/errors/authConfiguration";
import { filter } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class TestCodeService {
  testCode: string = "";
  emailId: string = "";
  candidateId: number;
  allTestDetails: TsCodeValid;
  testInfo: TsCodeValid[] = [];
  isLateralTest: boolean;
  candidateInfo: any = [];
  isResumeTest: boolean;
  testCodeForSummary = "";
  testDetails: any;
  searchTestName: string = "";
  testCodeDetails: any;
  testCodeForReports = "";
  user: any;
  password: string = "";
  userName: string = "";
  testDurationInMins = 0;
  isExternalDrive = false;

  private allTestDetailsSubject = new BehaviorSubject<any>(null);

  allTestDetails$ = this.allTestDetailsSubject.asObservable();

  // Method to update the allTestDetails
  updateAllTestDetails(details: any) {
    this.allTestDetailsSubject.next(details); // Step 3: Emit new value
  }

  constructor(
    private baseService: BaseService,
    private oauthService: OAuthService
  ) {}

  // Handle token received or present
  ngOnInit() {}

  // Trigger Microsoft login
  configureMicroSoftLogin() {
    this.oauthService.configure(authConfig); // Configure OAuth
    this.oauthService.loadDiscoveryDocumentAndLogin().then(() => {
      this.oauthService.setupAutomaticSilentRefresh(); // Silent token refresh
    });
  }

  getTestDetails(): Observable<TsCodeValid[]> {
    const isExternal = sessionStorage.getItem("isExternalDrive");
    return this.baseService.getTestDetails(
      this.testCode,
      this.emailId,
      isExternal === "false" ? false : Boolean(isExternal)
    );
  }
}
