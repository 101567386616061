import { Component, OnInit } from "@angular/core";
import { LocationStrategy } from "@angular/common";
import { BaseService } from "../services/baseService";
import { EndTestService } from "../services/end-test.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-allowed-for-test",
  templateUrl: "./allowed-for-test.component.html",
  styleUrls: ["./allowed-for-test.component.css"],
})
export class AllowedForTestComponent implements OnInit {
  isMalpractice = false;
  navigationTimeout: any;
  constructor(
    private location: LocationStrategy,
    private baseService: BaseService,
    private endTestService: EndTestService,
    private router: Router
  ) {
    this.location.onPopState(() => {
      this.baseService.isBackButtonClicked = true;
      return false;
    });
  }

  ngOnInit() {
    if (this.endTestService.endTestInfo) {
      this.isMalpractice = this.endTestService.endTestInfo.isMalpractice;
    }
    if (this.router.url === "/assessmentEnd") {
      this.navigationTimeout = setTimeout(() => {
        this.router.navigate(["/"]); // Replace '/initialPage' with your actual initial route4
        window.location.reload();
      }, 10000);
    }
    this.exitFullscreen();
  }

  exitFullscreen(): void {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if ((<any>document).webkitExitFullscreen) {
      (<any>document).webkitExitFullscreen();
    } else if ((<any>document).mozCancelFullScreen) {
      (<any>document).mozCancelFullScreen();
    } else if ((<any>document).msExitFullscreen) {
      (<any>document).msExitFullscreen();
    }
  }
  ngOnDestroy(): void {
    // Clear the timeout if the component is destroyed before 10 seconds
    if (this.navigationTimeout) {
      clearTimeout(this.navigationTimeout);
    }
  }
}
