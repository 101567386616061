import { Urls } from "./../urls";
import { SaveAnswer } from "./../onlineTestReducer";
import { Component, OnInit, HostListener, OnDestroy } from "@angular/core";

import { QuestionsService } from "../services/questions.service";
import { PageService } from "../services/page.service";
import { InstructionsService } from "../services/instructions.service";
import { TestCodeService } from "../services/test-code.service";
import { SaveAnswerService } from "../services/save-answer.service";
import { UserActionTypes } from "../onlineTestReducer";
import { EndTestService } from "../services/end-test.service";
import { timer } from "rxjs";
import { take, map, distinctUntilChanged } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { Router } from "@angular/router";
import { CandidateInfoService } from "../services/candidate-info.service";
import { BaseService } from "../services/baseService";
import { ToasterService } from "../services/toaster.service";
import { Actions, ofType } from "@ngrx/effects";
@Component({
  selector: "app-questions",
  templateUrl: "./questions.component.html",
  styleUrls: ["./questions.component.css", "../commonCss.css"],
})
export class QuestionsComponent implements OnInit {
  event: any;
  questionsInfo: any = [];
  questionId: number;
  arr: any = [];
  questionNumbers: any = [];
  selectedOption: any;
  pagedQuestions: any = [];
  sectionInfo: any = [];
  isColorChanged: boolean = false;
  isactive: boolean = false;
  count: any;
  selectedAnswer: number = 0;
  countInMinutes: any;
  testDuration: number = 0;
  testTime: number = 59;
  clearAnswer: boolean[] = [false];
  arr2: any = [];
  saveAnswerInfo: any = [];
  answeredEarlier: boolean = false;
  questionNumber: number;
  page: number;
  endPage: number;
  totalDuration: number = 0;
  sectionId: number;
  numberOfSections: number;
  timeLimited: number;
  timeDanger: boolean = false;
  questionPosition: number;
  isSectionSame: boolean;
  candidateName: string;
  isTestStarted: boolean = false;
  timeInterval: any = null;
  timeUpdate: any = null;
  timeLast: any = null;
  timeEnd: any = null;
  arr3: any = [];
  isTimeOut: boolean = false;
  noOfWarnings: number = 0;
  newWindow: boolean = false;
  isPageSelected: boolean = false;
  isSectionSelected: boolean = false;
  completionText: string;
  isPageAnswered: boolean = false;
  updateErrorCount = 0;
  message: string = "";
  isBoxOpened: boolean = false;
  isEndTest: boolean = false;
  endTestManual: boolean = false;
  isError: boolean = false;
  isCancelActivated: boolean = false;
  isChecked: boolean = false;
  questionText: string = "";
  option1: string = "";
  option2: string = "";
  option3: string = "";
  option4: string = "";
  pager: {
    totalNumberOfPages: number;
    currentPage: number;
    pageSize: number;
    startIndex: number;
    endIndex: number;
    isNextButtonDisabled: boolean;
  };
  sectionQuestionsLength;
  isNextButtonVisible = true;
  currentQuestionIndex = 0;
  currentSectionIndexValue = 0;
  isLastSection = false;
  isSaveAndEndTestButtonsVisible = false;
  isFullScreenExit: boolean = false;
  warningCount: number = 0;
  isSaveInProgress = false;
  intervalId: any;
  remainingMinutes: number = 0;
  remainingSeconds: number = 0;

  constructor(
    private questionBank: QuestionsService,
    private pageService: PageService,
    private instructionInfo: InstructionsService,
    private testInfo: TestCodeService,
    private baseService: BaseService,
    private saveAnswerService: SaveAnswerService,
    private endTestService: EndTestService,
    private store: Store<any>,
    private router: Router,
    private candidateService: CandidateInfoService,
    private urls: Urls
  ) {}
  @HostListener("window:focus")
  warning() {
    this.isFullScreenExit = true;
  }
  @HostListener("copy", ["$event"]) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
    this.isFullScreenExit = true;
  }
  @HostListener("paste", ["$event"]) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
    this.isFullScreenExit = true;
  }
  @HostListener("cut", ["$event"]) blockCut(e: KeyboardEvent) {
    e.preventDefault();
    this.isFullScreenExit = true;
  }
  @HostListener("window:blur")
  newTab() {
    // if (this.baseService.isTestStarted) {
    this.warningCount++;
    this.isFullScreenExit = true;
    if (this.warningCount >= 2) {
      this.baseService.newTabOpened = true;

      this.endTest(true);
    }
    // }
  }
  @HostListener("window:beforeunload", ["$event"])
  handleBeforeUnload(event: Event) {
    this.saveAnswerService.updateInfo = {
      canId: this.instructionInfo.canId,
      testId: this.testInfo.allTestDetails.testCodeInfo.test.testId,
      uuid: this.instructionInfo.uuid,
      elapsedTimeinMins: this.totalDuration - this.remainingMinutes,
      testCode: this.testInfo.allTestDetails.testCodeInfo.test.testCode,
      remainingTestMinutes: this.remainingMinutes,
      remainingTestSeconds: this.remainingSeconds,
    };
    this.store.dispatch({ type: UserActionTypes.UPDATE_SUMMARY });
  }

  ngOnInit() {
    this.enterFullscreen();
    this.remainingMinutes =
      this.testInfo.allTestDetails.testCodeInfo.candidate
        .remainingTestMinutes !==
      this.testInfo.allTestDetails.testCodeInfo.test.testDurationinMins
        ? this.testInfo.allTestDetails.testCodeInfo.candidate
            .remainingTestMinutes
        : this.testInfo.allTestDetails.testCodeInfo.candidate
            .remainingTestMinutes - 1;
    this.remainingSeconds =
      this.testInfo.allTestDetails.testCodeInfo.candidate.remainingTestSeconds;
    this.startTimer();
    this.baseService.isTestStarted = true;
    this.sectionInfo = this.questionBank.sectionInfo;

    this.questionsInfo = this.questionBank.questionsInfo;
    this.questionNumbers = this.questionBank.questionNumbers;
    if (this.sectionInfo) {
      this.sectionMapping(this.sectionInfo[0].sectionId);
    }
    this.totalDuration = this.remainingMinutes;
    // let duration =
    //   this.testInfo.allTestDetails.testCodeInfo.candidate.remainingTestMinutes *
    //   1000;
    this.questionNumber = 1;
    if (this.questionBank.elapsedTime == undefined) {
      this.questionBank.elapsedTime = 0;
    }
    if (this.testInfo.isResumeTest) {
      this.candidateName =
        this.testInfo.allTestDetails.testCodeInfo.candidate.name;
    } else {
      this.candidateName = sessionStorage.getItem("name");
    }
    this.numberOfSections = this.sectionInfo.length;
    this.saveAnswerService.updateInfo = {
      canId: this.instructionInfo.canId,
      testId: this.testInfo.allTestDetails.testCodeInfo.test.testId,
      uuid: this.instructionInfo.uuid,
      elapsedTimeinMins: this.totalDuration - this.remainingMinutes,
      testCode: this.testInfo.allTestDetails.testCodeInfo.test.testCode,
      remainingTestMinutes: this.remainingMinutes,
      remainingTestSeconds: this.remainingSeconds,
    };
    this.store.dispatch({ type: UserActionTypes.UPDATE_SUMMARY });
    this.store.select("updateSummary").subscribe((data) => {});

    // this.enterFullscreen();

    let updateElapsedTime = setInterval(() => {
      this.saveAnswerService.updateInfo = {
        canId: this.instructionInfo.canId,
        testId: this.testInfo.allTestDetails.testCodeInfo.test.testId,

        uuid: this.instructionInfo.uuid,
        elapsedTimeinMins: this.totalDuration - this.remainingMinutes,
        testCode: this.testInfo.allTestDetails.testCodeInfo.test.testCode,
        remainingTestMinutes: this.remainingMinutes,
        remainingTestSeconds: this.remainingSeconds,
      };
      this.store.dispatch({ type: UserActionTypes.UPDATE_SUMMARY });
      this.store.select("updateSummary").subscribe((data) => {
        if (data.error) {
          if (data.error.status == 0) {
            this.updateErrorCount++;
            if (this.updateErrorCount == 1) {
              this.newWindow = true;
              clearInterval(this.timeInterval);
              clearInterval(this.timeUpdate);
              clearInterval(this.timeLast);
              clearInterval(updateElapsedTime);
              this.isBoxOpened = true;
              this.message =
                "Please check your network connection and resume the test";
              this.isError = true;
            }
          }
          if (data.error.status >= 500) {
            this.updateErrorCount++;
            if (this.updateErrorCount == 1) {
              this.newWindow = true;
              clearInterval(this.timeInterval);
              clearInterval(this.timeUpdate);
              clearInterval(this.timeLast);
              clearInterval(updateElapsedTime);
              this.isBoxOpened = true;
              this.message =
                "There is a problem in server please wait for some time";
              this.isError = true;
            }
          }
        }
      });
    }, 60000);
    this.timeLimited = Math.floor(
      this.testInfo.allTestDetails.testCodeInfo.candidate.remainingTestMinutes /
        10
    );
    this.timeLast = setInterval(() => {
      if (this.remainingMinutes <= this.timeLimited) {
        this.timeDanger = true;
      }
    }, 1000);

    this.timeEnd = setInterval(() => {
      if (this.remainingMinutes == 0 && this.remainingSeconds == 0) {
        this.isTimeOut = true;
        clearInterval(this.timeInterval);
        clearInterval(this.timeUpdate);
        clearInterval(this.timeLast);
        clearInterval(this.timeEnd);
      }
    }, 5000);
  }
  setPage(page: number) {
    this.isPageAnswered = true;
    if (!this.isPageSelected) {
      this.saveAnswer();
    }
    this.isPageSelected = false;
    this.selectedOption = 0;
    this.answeredEarlier = false;
    let j = 0;

    const currentSectionQuestions = this.questionsInfo.filter(
      (question: any) => question.sectionId === this.sectionId
    );

    // Get the length of the questions for the current section
    this.sectionQuestionsLength = currentSectionQuestions.length;
    if (currentSectionQuestions[this.questionNumber - 1]) {
      this.questionId =
        currentSectionQuestions[this.questionNumber - 1].questionId;
    }
    if (this.questionNumber === currentSectionQuestions.length) {
      this.isNextButtonVisible = false;
      if (this.sectionInfo.length - 1 === this.currentSectionIndexValue) {
        this.isSaveAndEndTestButtonsVisible = true;
        this.isLastSection = true;
      } else {
        this.isLastSection = false;
      }
    } else {
      this.isNextButtonVisible = true;
      this.isSaveAndEndTestButtonsVisible = false;
      this.isLastSection = false;
    }

    for (let i = 0; i < this.sectionQuestionsLength; i++) {
      this.endPage = this.arr2[this.arr.length - 1];
      if (page == this.arr2[i]) {
        this.questionNumber = this.arr2[i];
        page = this.arr[i];
        this.page = this.arr[i];
        break;
      } else if (page == this.arr[i]) {
        this.questionNumber = this.arr2[i];
      }
    }

    this.pager = this.pageService.getPageParameters(
      this.questionBank.questionsInfo.length,
      page,
      this.endPage
    );
    if (page < 0 || page > this.questionsInfo.length) {
      return;
    }

    this.pagedQuestions = currentSectionQuestions.filter(
      (question: any) =>
        question.sectionId == this.sectionId &&
        question.questionId == this.questionId
    );
    this.questionText = this.pagedQuestions[0].questionText;
    this.questionText = this.questionText.replace(
      /\n/g,
      '<br style="padding-left:5px"/>'
    );
    this.option1 = this.pagedQuestions[0].option1;
    this.option1 = this.option1.replace(/\n/g, "<br/>");
    this.option2 = this.pagedQuestions[0].option2;
    this.option2 = this.option2.replace(/\n/g, "<br/>");
    this.option3 = this.pagedQuestions[0].option3;
    this.option3 = this.option3.replace(/\n/g, "<br/>");
    this.option4 = this.pagedQuestions[0].option4;
    this.option4 = this.option4.replace(/\n/g, "<br/>");
    this.selectedAnswer = this.pagedQuestions[0].selectedAnswer;
    if (this.selectedAnswer != 0) {
      this.answeredEarlier = true;
    } else {
      this.answeredEarlier = false;
    }

    this.arr3 = [];
    for (
      let index = 0;
      index < this.questionBank.questionsInfo.length;
      index++
    ) {
      if (this.sectionId == this.questionBank.questionsInfo[index].sectionId) {
        ++j;
        if (
          this.questionBank.questionsInfo[index].selectedAnswer == 1 ||
          this.questionBank.questionsInfo[index].selectedAnswer == 2 ||
          this.questionBank.questionsInfo[index].selectedAnswer == 3 ||
          this.questionBank.questionsInfo[index].selectedAnswer == 4
        ) {
          --j;
          this.arr3[j] = j;
          ++j;
        }
      }
    }
    if (this.currentQuestionIndex < this.sectionQuestionsLength - 1) {
      this.currentQuestionIndex++;
    }
  }
  sectionChange(sectionId: number, type?: string) {
    let index = null;
    let sectionIndex = this.sectionInfo.findIndex(
      (val: { sectionId: number }) => val.sectionId === sectionId
    );
    if (type) {
      if (type === "next") {
        index = sectionIndex + 1;
      } else {
        index = sectionIndex - 1;
      }
    } else {
      index = sectionIndex;
    }

    if (index < this.sectionInfo.length && index >= 0) {
      const changedSectionId = this.sectionInfo[index].sectionId;
      this.currentSectionIndexValue = index;

      this.isSaveAndEndTestButtonsVisible = false;
      this.isLastSection = false;

      this.sectionMapping(changedSectionId);
    } else {
      this.isSaveAndEndTestButtonsVisible = true;
      this.isLastSection = true;
    }
  }

  questionChange(qnumber: number, type?: string) {
    if (type) {
      if (type === "nextQuestion") {
        this.questionNumber = qnumber + 1;
      } else {
        this.questionNumber = qnumber - 1;
      }
    } else {
      this.questionNumber = qnumber;
    }

    this.setPage(this.questionNumber);
  }

  sectionMapping(sectionId: number) {
    this.isPageAnswered = true;
    this.isSectionSelected = false;
    this.arr = [];
    this.arr2 = [];
    this.arr3 = [];
    let j = 0;
    this.questionNumber = 1;
    this.page = 1;
    this.isSectionSame = false;
    for (
      let index = 0;
      index < this.questionBank.questionsInfo.length;
      index++
    ) {
      if (sectionId == this.questionBank.questionsInfo[index].sectionId) {
        this.sectionId = sectionId;

        if (j == 0) {
          this.setPage(index);
        }
        this.arr[j] = index;

        this.arr2[j] = ++j;
        if (
          this.questionBank.questionsInfo[index].selectedAnswer == 1 ||
          this.questionBank.questionsInfo[index].selectedAnswer == 2 ||
          this.questionBank.questionsInfo[index].selectedAnswer == 3 ||
          this.questionBank.questionsInfo[index].selectedAnswer == 4
        ) {
          --j;
          this.arr3[j] = j;
          ++j;
        }
      } else {
        this.isSectionSame = false;
      }
    }
    this.selectedOption = 0;
    this.answeredEarlier = false;
    this.isactive = !this.isactive;
  }
  checkedOption(event: any) {
    this.event = event;
    this.selectedOption = parseInt(event.target.value);
    this.isChecked = true;
  }

  saveAnswer() {
    if (this.selectedOption === 0 || this.selectedOption === undefined) {
      return;
    } else {
      this.saveAnswerService.saveAnswerInfo = {
        canId: this.instructionInfo.canId,
        testId: this.testInfo.allTestDetails.testCodeInfo.test.testId,
        uuid: this.instructionInfo.uuid,
        questionId: this.questionId,
        selectedAnswer: this.selectedOption,
        answeredEarlier: this.answeredEarlier,
        testCode: this.testInfo.allTestDetails.testCodeInfo.test.testCode,
      };
      this.store.dispatch({ type: UserActionTypes.SAVE_ANSWER });
      this.store
        .select((state) => state.saveAnswer)
        .pipe(distinctUntilChanged())
        .subscribe((data) => {
          try {
            if (this.isPageAnswered) {
              this.pagedQuestions[0].selectedAnswer = this.selectedOption;
              this.answeredEarlier = true;
            } else {
              this.answeredEarlier = false;
            }
            this.isPageAnswered = false;
          } catch (error) {
            console.log(error);
          }
        });
      for (let index = 0; index < this.arr.length; index++) {
        if (this.sectionId == this.questionsInfo[this.arr[index]].sectionId) {
          this.isSectionSame = true;
        }
        if (this.arr[index] == this.page && this.isSectionSame) {
          this.questionPosition = this.arr[index];
        }
      }
      this.selectedOption = 0;
    }
  }
  clear() {
    if (this.selectedAnswer || this.selectedOption) {
      let j = 0;
      this.selectedOption = 0;
      this.answeredEarlier = false;
      this.isChecked = false;
      this.saveAnswerService.clearAnswerInfo = {
        testId: this.testInfo.allTestDetails.testCodeInfo.test.testId,
        canId: this.instructionInfo.canId,
        questionId: this.questionId,
        testCode: this.testInfo.allTestDetails.testCodeInfo.test.testCode,
      };
      this.store.dispatch({ type: UserActionTypes.CLEAR_ANSWER });
      this.store.select("clearAnswer").subscribe((data) => {
        this.answeredEarlier = false;
        this.selectedAnswer = 0;
        this.pagedQuestions[0].selectedAnswer = 0;
      });
      this.arr3 = [];
      for (
        let index = 0;
        index < this.questionBank.questionsInfo.length;
        index++
      ) {
        if (
          this.sectionId == this.questionBank.questionsInfo[index].sectionId
        ) {
          ++j;
          if (
            this.questionBank.questionsInfo[index].selectedAnswer == 1 ||
            this.questionBank.questionsInfo[index].selectedAnswer == 2 ||
            this.questionBank.questionsInfo[index].selectedAnswer == 3 ||
            this.questionBank.questionsInfo[index].selectedAnswer == 4
          ) {
            --j;
            this.arr3[j] = j;
            ++j;
          }
        }
      }
    }
  }
  endTest(
    isTestEndWithWarning?: boolean,
    remainingMinutes?: number,
    remainingSeconds?: number
  ) {
    this.saveAnswer();
    this.completionText = "Normal";
    this.newWindow = true;
    let message;
    if (this.isTimeOut) {
      message = true;
    } else if (this.noOfWarnings == 4) {
      message = true;
      this.completionText = "Abnormal";
    } else {
      this.isBoxOpened = true;
      this.endTestManual = true;
      this.isCancelActivated = true;
      this.message = "Are you sure want to end the test?";
    }
    if (message == true) {
      this.endTestService.endTestInfo = {
        canId: this.instructionInfo.canId,
        testId: this.testInfo.allTestDetails.testCodeInfo.test.testId,
        uuid: this.instructionInfo.uuid,
        elapsedTimeinMins: this.totalDuration - this.remainingMinutes,
        completionText: this.completionText,
        testCode: this.testInfo.allTestDetails.testCodeInfo.test.testCode,
        isMalpractice: this.warningCount == 2 ? true : false,
        remainingTestMinutes: remainingMinutes,
        remainingTestSeconds: remainingSeconds,
      };
      this.store.dispatch({ type: UserActionTypes.END_TEST });
      this.store.select("endTest").subscribe((data) => {
        // sessionStorage.clear();
        // this.router.navigate(["assessmentEnd"]);
      });
    }
    this.baseService.isTestStarted = false;
    if (isTestEndWithWarning) {
      this.BoxClose();
    }
    // this.isFullScreenExit = true;
  }
  BoxClose() {
    this.isBoxOpened = false;
    this.newWindow = false;
    if (this.isEndTest) {
      this.endTest();
    }
    if (this.isError) {
      window.location.reload();
    }
    if (this.endTestManual) {
      this.endTestService.endTestInfo = {
        canId: this.instructionInfo.canId,
        testId: this.testInfo.allTestDetails.testCodeInfo.test.testId,
        uuid: this.instructionInfo.uuid,

        elapsedTimeinMins: this.totalDuration - this.remainingMinutes,
        completionText: this.completionText,
        testCode: this.testInfo.allTestDetails.testCodeInfo.test.testCode,
        isMalpractice: this.warningCount == 2 ? true : false,
      };
      this.store.dispatch({ type: UserActionTypes.END_TEST });
      this.store.select("endTest").subscribe((data) => {
        // this.router.navigate(["assessmentEnd"]);
      });
    }
  }
  Cancel() {
    this.endTestManual = false;
    this.isCancelActivated = false;
    this.isBoxOpened = false;
  }

  enterFullscreen(): void {
    this.isFullScreenExit = false;
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if ((<any>elem).webkitRequestFullscreen) {
      (<any>elem).webkitRequestFullscreen();
    } else if ((<any>elem).msRequestFullscreen) {
      (<any>elem).msRequestFullscreen();
    }
  }

  @HostListener("document:keydown", ["$event"])
  blockCtrlTab(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === "Tab") {
      event.preventDefault();
      this.isFullScreenExit = true;
      this.warningCount++;
      if (this.warningCount >= 2) {
        this.baseService.newTabOpened = true;
        this.endTest(true);
      }
    }
  }

  @HostListener("document:fullscreenchange", [])
  @HostListener("document:webkitfullscreenchange", [])
  @HostListener("document:mozfullscreenchange", [])
  @HostListener("document:MSFullscreenChange", [])
  onFullscreenChange(): void {
    if (!document.fullscreenElement) {
      this.isFullScreenExit = true;
      this.warningCount++;
      if (this.warningCount >= 2) {
        this.baseService.newTabOpened = true;

        this.endTest(true);
      }
    } else {
      this.isFullScreenExit = false;
    }
  }
  startTimer(): void {
    this.intervalId = setInterval(() => {
      if (this.remainingMinutes === 0 && this.remainingSeconds === 0) {
        // Timer has finished
        clearInterval(this.intervalId);
      } else {
        this.decrementTime();
      }
    }, 1000);
  }

  decrementTime(): void {
    if (this.remainingSeconds > 0) {
      this.remainingSeconds--;
    } else if (this.remainingMinutes > 0) {
      this.remainingMinutes--;
      this.remainingSeconds = 59;
    }
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
  endTimer() {
    this.endTest(false, this.remainingMinutes, this.remainingSeconds);
    this.isFullScreenExit = true;
  }
}
