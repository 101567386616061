import { Component, OnInit } from '@angular/core';
import { Urls } from '../urls';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-certificate-checking',
  templateUrl: './certificate-checking.component.html',
  styleUrls: ['./certificate-checking.component.css']
})
export class CertificateCheckingComponent implements OnInit {
  navigation: any;
  constructor(public urls: Urls, private http: HttpClient, private router: Router) { }
  ngOnInit() {
    this.navigation = setInterval(() => {
      this.http.get(this.urls.certificateUrl).subscribe(data => {
      }, err => {
        if (err.status == 200) {
          this.router.navigate(['/'])
          this.ngOnDestroy();
        }
      })
    }, 1000)
  }
  ngOnDestroy() {
    clearInterval(this.navigation);
  }
}
