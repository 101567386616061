<div class="candidate" id="body">
  <h2 id="title" class="header">Candidate Form</h2>
  <form #candidForm="ngForm" class="candidateForm">
    <div class="formContainer">
      <div class="row g-3 rowSpace">
        <div class="col-lg-4 col-md-4 p-3">
          <div class="inputContainer">
            <label for="fname" class="inputLabel ng-star-inserted">
              <h6>Full Name<span style="color: red">*</span></h6>
            </label>
            <input
              type="text"
              class="form-control inputField col-sm-4"
              id="fname"
              placeholder="E.g. John Doe"
              [disabled]="isDisabled"
              onfocus="this.placeholder=''"
              onblur="this.placeholder='E.g. John Doe'"
              [(ngModel)]="fname"
              name="fname"
              ngModel
              required
              #uname="ngModel"
            />
            <mat-error class="mat-error" *ngIf="uname.touched && uname.errors">
              Full name is required.
            </mat-error>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 p-3">
          <div class="inputContainer">
            <label class="inputLabel ng-star-inserted" for="gender">
              <h6>Gender<span style="color: red">*</span></h6>
            </label>
            <div class="select-input">
              <mat-select
              id="gender"
              name="gender"
              [(ngModel)]="pgender"
              (selectionChange)="selGender($event)"
              [disabled]="isDisabled"
              ngModel
              #ugender="ngModel"
              class="inputField col-sm-4 input"
              placeholder="Select Gender"
              required
            >
              <mat-option *ngFor="let gender of genderTypes" [value]="gender">
                {{ gender }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="ugender.touched && ugender.errors">
              Gender is required.
            </mat-error>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 p-3">
          <div class="inputContainer">
            <label for="emailId" class="inputLabel ng-star-inserted">
              <h6>Email Id<span style="color: red">*</span></h6>
            </label>
            <input
              type="email"
              class="form-control inputField col-sm-4"
              id="emailId"
              name="emailId"
              placeholder="E.g. abcd@gmail.com"
              onfocus="this.placeholder=''"
              onblur="this.placeholder='E.g. abcd@gmail.com'"
              [(ngModel)]="emailId"
              [disabled]="true"
              ngModel
              required
              email
              #uemail="ngModel"
            />
            <mat-error
              class="mat-error"
              *ngIf="uemail.touched && uemail.errors"
            >
              Email is required.
            </mat-error>
          </div>
        </div>
      </div>
      <div class="row g-3 rowSpace">
        <div class="col-lg-4 col-md-4 p-3">
          <div class="inputContainer">
            <label for="mobNo" class="inputLabel ng-star-inserted">
              <h6>Mobile No<span style="color: red">*</span></h6>
            </label>
            <input
              type="number"
              class="form-control inputField col-sm-4"
              id="mobNo"
              name="mobNo"
              placeholder="E.g. 9876543210"
              onfocus="this.placeholder=''"
              onblur="this.placeholder='E.g. 9876543210'"
              [(ngModel)]="mobNo"
              [disabled]="isDisabled"
              maxlength="10"
              pattern="^[0-9]{10}$"
              ngModel
              required
              #umobNo="ngModel"
            />
            <mat-error
              class="mat-error"
              *ngIf="umobNo.touched && umobNo.errors?.required"
            >
              Mobile number is required.
            </mat-error>

            <mat-error
              class="mat-error"
              *ngIf="umobNo.dirty && umobNo.errors?.pattern"
            >
              Enter a valid mobile number.
            </mat-error>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 p-3">
          <div class="inputContainer">
            <label for="govIdType" class="inputLabel ng-star-inserted">
              <h6>Id Type<span style="color: red">*</span></h6>
            </label>
            <div class="select-input">
              <mat-select
              id="govIdType"
              name="govIdType"
              [(ngModel)]="pgovIdType"
              placeholder="Select ID"
              [disabled]="isDisabled"
              ngModel
              required
              #ugovId="ngModel"
              (selectionChange)="selGovId($event)"
              class="inputField col-sm-4 input"
            >
              <mat-option *ngFor="let govId of govtIdTypes" [value]="govId">
                {{ govId }}
              </mat-option>
            </mat-select>
            <mat-error
              class="mat-error"
              *ngIf="ugovId.touched && ugovId.errors"
            >
              Id type is required.
            </mat-error>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 p-3">
          <div class="inputContainer">
            <label for="govNo" class="inputLabel ng-star-inserted">
              <h6>Id No<span style="color: red">*</span></h6>
            </label>
            <input
              type="text"
              class="form-control inputField col-sm-4"
              id="govNo"
              name="govNo"
              placeholder="E.g. 1234567890"
              onfocus="this.placeholder=''"
              onblur="this.placeholder='E.g. 1234567890'"
              [(ngModel)]="govIdNo"
              [disabled]="isDisabled"
              ngModel
              required
              #ugovIdNo="ngModel"
            />
            <mat-error
              class="mat-error"
              *ngIf="ugovIdNo.touched && ugovIdNo.errors"
            >
              Id number is required.
            </mat-error>
          </div>
        </div>
      </div>
      <div class="row g-3 rowSpace">
        <div class="col-lg-4 col-md-4 p-3">
          <div class="inputContainer">
            <label for="degree" class="inputLabel ng-star-inserted">
              <h6>Degree<span style="color: red">*</span></h6>
            </label>
            <div class="select-input">
              <mat-select
              id="degree"
              name="degree"
              [(ngModel)]="pdegree"
              placeholder="Select Degree"
              [disabled]="isDisabled"
              ngModel
              required
              #degree="ngModel"
              class="inputField col-sm-4 input"
              (selectionChange)="selDegree($event)"
            >
              <mat-option value="B.Tech">B.Tech</mat-option>
              <mat-option value="B.Com">B.Com</mat-option>
              <mat-option value="B.Sc">B.Sc</mat-option>
              <mat-option value="M.Tech">M.Tech</mat-option>
              <mat-option value="M.Sc">M.Sc</mat-option>
              <mat-option value="MCA">MCA</mat-option>
              <mat-option value="MBA">MBA</mat-option>
              <mat-option value="Other">Other</mat-option>
            </mat-select>
            <mat-error
              class="mat-error"
              *ngIf="degree.touched && degree.errors"
            >
              Degree is required.
            </mat-error>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 p-3">
          <div class="inputContainer">
            <label for="college" class="inputLabel ng-star-inserted">
              <h6>College Name<span style="color: red">*</span></h6>
            </label>
            <input
              type="text"
              class="form-control inputField col-sm-4"
              id="college"
              name="college"
              placeholder="E.g. ABC"
              onfocus="this.placeholder=''"
              onblur="this.placeholder='E.g. ABC College'"
              [(ngModel)]="college"
              [disabled]="isDisabled"
              ngModel
              required
              #ucollege="ngModel"
            />
            <mat-error
              class="mat-error"
              *ngIf="ucollege.touched && ucollege.errors"
            >
              College name is required.
            </mat-error>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 p-3">
          <div class="inputContainer">
            <label for="department" class="inputLabel ng-star-inserted">
              <h6>Department<span style="color: red">*</span></h6>
            </label>
            <input
              type="text"
              class="form-control inputField col-sm-4"
              id="department"
              name="department"
              placeholder="E.g. CSE"
              onfocus="this.placeholder=''"
              onblur="this.placeholder='E.g. CSE'"
              [(ngModel)]="department"
              [disabled]="isDisabled"
              ngModel
              required
              #udepartment="ngModel"
            />
            <mat-error
              class="mat-error"
              *ngIf="udepartment.touched && udepartment.errors"
            >
              Department is required.
            </mat-error>
          </div>
        </div>
      </div>
      <div class="row g-3 rowSpace">
        <div class="col-lg-4 col-md-4 p-3">
          <div class="inputContainer">
            <label for="yog" class="inputLabel ng-star-inserted">
              <h6>Year of Graduation<span style="color: red">*</span></h6>
            </label>
            <input
              type="number"
              class="form-control inputField col-sm-4"
              id="yog"
              name="yog"
              placeholder="E.g. 2018"
              onfocus="this.placeholder=''"
              maxlength="4"
              pattern="[2]{1}[0]{1}[0-9]{1}[0-9]{1}"
              onblur="this.placeholder='E.g. 2018'"
              [(ngModel)]="yearOfGraduation"
              [disabled]="isDisabled"
              ngModel
              required
              #uyog="ngModel"
            />
            <mat-error
              class="mat-error"
              *ngIf="uyog.touched && uyog.errors?.required"
            >
              Year of graduation is required.
            </mat-error>
            <mat-error
              class="mat-error"
              *ngIf="uyog.dirty && uyog.errors?.pattern"
            >
              Enter valid year.
            </mat-error>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 p-3">
          <div class="inputContainer">
            <label for="percentage" class="inputLabel ng-star-inserted">
              <h6>Percentage/GPA<span style="color: red">*</span></h6>
            </label>
            <input
              type="number"
              maxlength="5"
              max="100"
              class="form-control col-sm-4 inputField"
              id="percentage"
              name="percentage"
              pattern="[0-9.]{0,3}([0-9]{1,2})"
              placeholder="E.g. 99.99"
              onfocus="this.placeholder=''"
              onblur="this.placeholder='E.g. 99.99'"
              [(ngModel)]="percentage"
              (blur)="percentageValidation()"
              [disabled]="isDisabled"
              ngModel
              required
              #upercentage="ngModel"
            />
            <mat-error
              class="mat-error"
              *ngIf="upercentage.touched && upercentage.errors?.required"
            >
              Percentage/GPA is required.
            </mat-error>
            <mat-error
              class="mat-error"
              *ngIf="upercentage.dirty && !upercentage.errors?.required && (upercentage.errors?.pattern || (upercentage.value >100 ||upercentage.value<1))"
            >
              Enter valid percentage/GPA.
            </mat-error>
          </div>
        </div>
      </div>
    </div>

    <div class="row"></div>
    <div *ngIf="isLateralTest">
      <div class="row">
        <label for="recentEmpName" class="col-sm-2">Recent Company</label>
        <input
          type="text"
          class="col-sm-3 form-control"
          id="recentEmpName"
          name="recentEmpName"
          placeholder="E.g. Cognine"
          onfocus="this.placeholder=''"
          onblur="this.placeholder='E.g. Microsoft'"
          [(ngModel)]="recentEmpName"
          [disabled]="isDisabled"
          ngModel
          required
          #urecEmpName="ngModel"
          [ngStyle]="{
            'border-left-color': urecEmpName.errors ? 'red' : 'green'
          }"
        />
        <label for="empPosition" class="col-sm-2">Position</label>
        <input
          type="text"
          class="form-control col-sm-3"
          id="empPosition"
          name="empPosition"
          placeholder="E.g. Associate engineer"
          onfocus="this.placeholder=''"
          onblur="this.placeholder='E.g. Senior Engineer'"
          [(ngModel)]="empPosition"
          [disabled]="isDisabled"
          ngModel
          required
          #uempPosition="ngModel"
          [ngStyle]="{
            'border-left-color': uempPosition.errors ? 'red' : 'green'
          }"
        />
      </div>
      <div class="row">
        <label for="startDate" class="col-sm-2">Start Date</label>
        <input
          type="text"
          class="form-control col-sm-3"
          id="startDate"
          name="startDate"
          placeholder="E.g. 01/01/2016"
          onfocus="(this.type='date')"
          (blur)="dateValidation()"
          (focus)="dateFocus()"
          onblur="(this.type='text')"
          [(ngModel)]="startDate"
          [disabled]="isDisabled"
          ngModel
          required
          #ustDate="ngModel"
          [ngStyle]="{ 'border-left-color': ustDate.errors ? 'red' : 'green' }"
        />
      </div>
      <div class="row">
        <label for="workingStatus" class="col-sm-2">Presently Working</label>
        <div class="col-sm-3" style="margin-top: 20px">
          <!-- <label for="ws">Yes</label>
          <input type="radio" id="wsTrue" name="ws" (click)="workTrue()" checked="{{pworkingStatus}}" [disabled]='isDisabled'>
          <label for="ws">No</label>
          <input type="radio" id="wsFalse" name="ws" (click)="workFalse()" checked="{{!pworkingStatus}}" [disabled]='isDisabled'> -->
          <ui-switch
            (change)="workTrue()"
            [disabled]="isDisabled"
            switchColor="lightgray"
            size="small"
            color="rgb(66,139,202)"
          ></ui-switch>
        </div>
        <label *ngIf="!workingStatus" for="endDate" class="col-sm-2"
          >End Date</label
        >
        <input
          *ngIf="!workingStatus"
          type="text"
          class="form-control col-sm-3"
          id="endDate"
          name="endDate"
          placeholder="E.g. 01/01/2018"
          (blur)="dateValidation()"
          (focus)="dateFocus()"
          onblur="(this.type='text')"
          onfocus="(this.type='date')"
          [(ngModel)]="endDate"
          [disabled]="isDisabled"
          ngModel
          required
          #ueDate="ngModel"
          [ngStyle]="{
            'border-left-color':
              ueDate.errors || isInvalidDate ? 'red' : 'green'
          }"
        />
      </div>
      <div class="row">
        <label for="currentCity" class="col-sm-2">Current City</label>
        <input
          type="text"
          id="currentCity"
          name="currentCity"
          class="form-control col-sm-3"
          placeholder="E.g. Hyderabad"
          onfocus="this.placeholder=''"
          onblur="this.placeholder='E.g. Hyderabad'"
          [(ngModel)]="currentCity"
          [disabled]="isDisabled"
          ngModel
          required
          #ucurrentCity="ngModel"
          [ngStyle]="{
            'border-left-color': ucurrentCity.errors ? 'red' : 'green'
          }"
        />
        <label for="totExp" class="col-sm-2">Total Experience</label>
        <input
          type="number"
          maxlength="2"
          min="0"
          max="30"
          id="totExp"
          name="totExp"
          class="form-control"
          [(ngModel)]="totExpYear"
          pattern="[0-9]{0,2}"
          [disabled]="isDisabled"
          style="width: 80px"
          ngModel
          required
          #utotExpYears="ngModel"
          [ngStyle]="{
            'border-left-color': utotExpYears.errors ? 'red' : 'green'
          }"
        />
        <label style="margin-left: 5px; margin-right: 10px">Years</label>
        <input
          type="number"
          id="totExpM"
          name="totExpM"
          class="form-control"
          [(ngModel)]="totExpMon"
          pattern="[0-9]{0,2}"
          maxlength="2"
          keydown="return false"
          min="0"
          max="12"
          style="width: 80px"
          [disabled]="isDisabled"
          ngModel
          required
          #utotExpMonths="ngModel"
          [ngStyle]="{
            'border-left-color':
              utotExpMonths.errors || totExpMon > 12 || totExpMon < 0
                ? 'red'
                : 'green'
          }"
        />
        <label style="margin-left: 5px">Months</label>
      </div>
    </div>
    <div class="row btn-placement">
      <div class="col-sm-9"></div>

      <button
        class="btn btn-primary"
        style="margin-left: 20px"
        (click)="saveCandDet()"
        id="navButtons"
        [disabled]="
          candidForm.invalid"
        *ngIf="!isLoading"
      >
        Continue
      </button>
      <button
        class="btn btn-primary"
        style="margin-left: 20px"
        *ngIf="isLoading"
        [disabled]="isLoading"
      >
        <i class="fa fa-spinner fa-spin"></i>Loading
      </button>
    </div>
  </form>
</div>
