import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class ToasterService {
  constructor(private snackBar: MatSnackBar, private toastr: ToastrService) {}

  showSuccess(message: string) {
    this.toastr.success(message, "Success");
  }

  showError(message: string) {
    this.toastr.error(message, "Failed");
  }

  private openSnackBar(message: string, panelClass: string) {
    const config = new MatSnackBarConfig();
    config.duration = 5000;
    config.panelClass = [panelClass];
    config.horizontalPosition = "end";
    config.verticalPosition = "top";

    this.snackBar.open(message, "Close", config);
  }
}
